import router from '@/router';

import Jsona from 'jsona';

import ClinicService from '@/services/ClinicService';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    clinicIsLoading: false,
    clinicsAreLoading: false,
    clinics: [],
    clinic: undefined,
};

export const mutations = {
    ADD_CLINIC(state, payload) {
        state.clinics.push(payload);
    },
    DELETE_CLINIC(state, payload) {
        const index = state.clinics.findIndex((value) => value.id === payload.id);
        state.clinics.splice(index, 1);
    },
    SET_CLINIC_LOADING_STATUS(state, payload) {
        state.clinicIsLoading = payload;
    },
    SET_CLINICS_LOADING_STATUS(state, payload) {
        state.clinicsAreLoading = payload;
    },
    SET_CLINICS(state, payload) {
        state.clinics = payload;
    },
    SET_CLINIC(state, payload) {
        state.clinic = payload;
    },
};

export const actions = {
    async fetchClinics({ commit }, params) {
        try {
            commit('SET_CLINICS_LOADING_STATUS', true);

            const response = await ClinicService.fetchClinics(params);
            const clinics = dataFormatter.deserialize(response.data);

            commit('SET_CLINICS', clinics);
            commit('SET_CLINICS_LOADING_STATUS', false);

            return clinics;
        } catch (error) {
            commit('SET_CLINICS', []);
            commit('SET_CLINICS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async fetchClinic({ commit }, params) {
        try {
            commit('SET_CLINIC_LOADING_STATUS', true);

            const response = await ClinicService.fetchClinic(params);
            const clinic = dataFormatter.deserialize(response.data);

            commit('SET_CLINIC', clinic);
            commit('SET_CLINIC_LOADING_STATUS', false);

            return clinic;
        } catch (error) {
            commit('SET_CLINIC', undefined);
            commit('SET_CLINICS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async saveClinic({ commit, dispatch }, payload) {
        let { method, clinic } = payload;

        try {
            commit('SET_CLINIC_LOADING_STATUS', true);

            delete clinic.links;

            const response = await ClinicService.saveClinic(
                method,
                dataFormatter.serialize({
                    stuff: { ...clinic, type: 'clinics' },
                })
            );

            clinic = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_CLINIC', clinic);
            }

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your clinic has been ${method === 'POST' ? 'created' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_CLINIC_LOADING_STATUS', false);

            return clinic;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.message,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_CLINIC_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async deleteClinic({ commit, dispatch }, payload) {
        try {
            commit('SET_CLINIC_LOADING_STATUS', true);

            const response = await ClinicService.deleteClinic(payload);

            commit('SET_CLINIC_LOADING_STATUS', false);
            commit('DELETE_CLINIC', payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: 'That clinic has been removed.',
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            await router
                .replace({
                    name: 'clinics.summary',
                })
                .catch(() => {
                    //...
                });

            return await response;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: `${err.message}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_CLINIC_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    setClinic({ commit }, payload) {
        commit('SET_CLINIC', payload);
    },
};

export const getters = {
    clinics: (state) => {
        return state.clinics;
    },
    clinic: (state) => {
        return state.clinic;
    },
    clinicsAreLoading: (state) => {
        return state.clinicsAreLoading;
    },
    clinicIsLoading: (state) => {
        return state.clinicIsLoading;
    },
};
