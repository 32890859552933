export const namespaced = true;

export const state = {
    modal: undefined,
};

export const mutations = {
    SET_MODAL(state, payload) {
        state.modal = payload;
    },
};

export const actions = {
    async renderModal({ commit }, payload) {
        commit('SET_MODAL', payload);
    },
    async closeModal({ commit }) {
        commit('SET_MODAL', undefined);
    },
};

export const getters = {
    modal: (state) => {
        return state.modal;
    },
};
