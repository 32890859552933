import client from '@/helpers/axios';

export default {
    async saveOrganization(method, organization) {
        if (method === 'PATCH') {
            try {
                return await client.patch(
                    `/v1/organizations/${organization.data.id}`,
                    organization
                );
            } catch (err) {
                return await err;
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/organizations`, organization);
            } catch (err) {
                return await err;
            }
        }
    },
    async fetchOrganizations() {
        try {
            return await client.get(`/v1/organizations`);
        } catch (err) {
            return await err;
        }
    },
    async fetchOrganization(organizationId) {
        try {
            return await client.get(`/v1/organizations/${organizationId}`);
        } catch (err) {
            return await err;
        }
    },
};
