import ApplicationService from '@/services/ApplicationService';

export const namespaced = true;

export const state = {
    applicationIsLoading: false,
    currencies: [{ text: 'USD', value: 'USD' }],
    networkError: undefined,
    states: [
        { text: 'Alabama', value: 'AL' },
        { text: 'Alaska', value: 'AK' },
        { text: 'Arizona', value: 'AZ' },
        { text: 'Arkansas', value: 'AR' },
        { text: 'California', value: 'CA' },
        { text: 'Colorado', value: 'CO' },
        { text: 'Connecticut', value: 'CT' },
        { text: 'Delaware', value: 'DE' },
        { text: 'Florida', value: 'FL' },
        { text: 'Georgia', value: 'GA' },
        { text: 'Hawaii', value: 'HI' },
        { text: 'Idaho', value: 'ID' },
        { text: 'Illinois', value: 'IL' },
        { text: 'Indiana', value: 'IN' },
        { text: 'Iowa', value: 'IA' },
        { text: 'Kansas', value: 'KS' },
        { text: 'Kentucky', value: 'KY' },
        { text: 'Louisiana', value: 'LA' },
        { text: 'Maine', value: 'MA' },
        { text: 'Maryland', value: 'MD' },
        { text: 'Massachusetts', value: 'MA' },
        { text: 'Michigan', value: 'MI' },
        { text: 'Minnesota', value: 'MN' },
        { text: 'Mississippi', value: 'MI' },
        { text: 'Missouri', value: 'MO' },
        { text: 'Montana', value: 'MT' },
        { text: 'Nebraska', value: 'NE' },
        { text: 'Nevada', value: 'NV' },
        { text: 'New Hampshire', value: 'NH' },
        { text: 'New Jersey', value: 'NJ' },
        { text: 'New Mexico', value: 'NM' },
        { text: 'New York', value: 'NY' },
        { text: 'North Carolina', value: 'NC' },
        { text: 'North Dakota', value: 'ND' },
        { text: 'North Dakota', value: 'ND' },
        { text: 'Ohio', value: 'OH' },
        { text: 'Oklahoma', value: 'OK' },
        { text: 'Oregon', value: 'OR' },
        { text: 'Pennsylvania', value: 'PA' },
        { text: 'Rhode Island', value: 'RI' },
        { text: 'South Carolina', value: 'SC' },
        { text: 'South Dakota', value: 'SD' },
        { text: 'Tennessee', value: 'TN' },
        { text: 'Texas', value: 'TX' },
        { text: 'Utah', value: 'UT' },
        { text: 'Vermont', value: 'VT' },
        { text: 'Virginia', value: 'VA' },
        { text: 'Washington', value: 'WA' },
        { text: 'West Virginia', value: 'WV' },
        { text: 'Wisconsin', value: 'WI' },
        { text: 'Wyoming', value: 'WY' },
    ],
    daysOfTheWeek: [
        { text: 'Monday', value: 'monday' },
        { text: 'Tuesday', value: 'tuesday' },
        { text: 'Wednesday', value: 'wednesday' },
        { text: 'Thursday', value: 'thursday' },
        { text: 'Friday', value: 'friday' },
        { text: 'Saturday', value: 'saturday' },
        { text: 'Sunday', value: 'sunday' },
    ],
    timezones: [
        { value: 'America/Puerto_Rico', text: 'Puerto Rico (Atlantic)' },
        { value: 'America/New_York', text: 'New York (Eastern)' },
        { value: 'America/Chicago', text: 'Chicago (Central)' },
        { value: 'America/Denver', text: 'Denver (Mountain)' },
        { value: 'America/Phoenix', text: 'Phoenix (MST)' },
        { value: 'America/Los_Angeles', text: 'Los Angeles (Pacific)' },
        { value: 'America/Anchorage', text: 'Anchorage (Alaska)' },
        { value: 'Pacific/Honolulu', text: 'Honolulu (Hawaii)' },
    ],
};

export const mutations = {
    SET_APPLICATION_LOADING_STATUS(state, payload) {
        state.applicationIsLoading = payload;
    },
    SET_NETWORK_ERROR(state, payload) {
        state.networkError = payload;
    },
};

export const actions = {
    async handleNetworkError({ commit }, payload) {
        commit('SET_NETWORK_ERROR', payload);
    },
    async requestSupport({ commit, dispatch }, payload) {
        commit('SET_APPLICATION_LOADING_STATUS', true);

        try {
            const response = await ApplicationService.requestSupport(payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your request has been received.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_APPLICATION_LOADING_STATUS', false);

            return response;
        } catch (err) {
            commit('SET_APPLICATION_LOADING_STATUS', false);

            return await Promise.reject(err.message);
        }
    },
};

export const getters = {
    currencies: (state) => {
        return state.currencies;
    },
    timezones: (state) => {
        return state.timezones;
    },
    states: (state) => {
        return state.states;
    },
    applicationIsLoading: (state) => {
        return state.applicationIsLoading;
    },
    networkError: (state) => {
        return state.networkError;
    },
    daysOfTheWeek: (state) => {
        return state.daysOfTheWeek;
    },
};
