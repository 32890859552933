import client from '@/helpers/axios';

export default {
    async requestSupport(method, ticket) {
        console.log('TICKT', ticket);

        if (method === 'PATCH') {
            try {
                return await client.patch(`/v1/support-tickets/${ticket.data.id}`, ticket);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/support-tickets`, ticket);
            } catch (err) {
                return await Promise.reject(err);
            }
        }
    },
    async fetchSupportTickets(organizationId) {
        try {
            return await client.get(`/v1/organizations/${organizationId}/support-tickets`);
        } catch (err) {
            return await err;
        }
    },
};
