import router from '@/router';

import Jsona from 'jsona';

import LessonService from '@/services/LessonService';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    lessonIsLoading: false,
    lessonsAreLoading: false,
    lessons: [],
    lesson: undefined,
};

export const mutations = {
    ADD_LESSON(state, payload) {
        state.lessons.push(payload);
    },
    DELETE_LESSON(state, payload) {
        const index = state.lessons.findIndex((value) => value.id === payload.id);
        state.lessons.splice(index, 1);
    },
    SET_LESSON_LOADING_STATUS(state, payload) {
        state.lessonIsLoading = payload;
    },
    SET_LESSONS_LOADING_STATUS(state, payload) {
        state.lessonsAreLoading = payload;
    },
    SET_LESSONS(state, payload) {
        state.lessons = payload;
    },
    SET_LESSON(state, payload) {
        state.lesson = payload;
    },
};

export const actions = {
    async fetchLessons({ commit }, params) {
        try {
            commit('SET_LESSONS_LOADING_STATUS', true);

            const response = await LessonService.fetchLessons(params);
            const lessons = dataFormatter.deserialize(response.data);

            commit('SET_LESSONS', lessons);
            commit('SET_LESSONS_LOADING_STATUS', false);

            return lessons;
        } catch (error) {
            commit('SET_LESSONS', []);
            commit('SET_LESSONS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async fetchLesson({ commit }, params) {
        try {
            commit('SET_LESSON_LOADING_STATUS', true);

            const response = await LessonService.fetchLesson(params);
            const lesson = dataFormatter.deserialize(response.data);

            commit('SET_LESSON', lesson);
            commit('SET_LESSON_LOADING_STATUS', false);

            return lesson;
        } catch (error) {
            commit('SET_LESSON', undefined);
            commit('SET_LESSONS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async saveLesson({ commit, dispatch }, payload) {
        let { method, lesson } = payload;

        try {
            commit('SET_LESSON_LOADING_STATUS', true);

            delete lesson.links;

            const response = await LessonService.saveLesson(
                method,
                dataFormatter.serialize({
                    stuff: { ...lesson, type: 'lessons' },
                })
            );

            lesson = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_LESSON', lesson);
            }

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your lesson has been ${method === 'POST' ? 'created' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_LESSON_LOADING_STATUS', false);

            return lesson;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.message,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_LESSON_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async deleteLesson({ commit, dispatch }, payload) {
        try {
            commit('SET_LESSON_LOADING_STATUS', true);

            const response = await LessonService.deleteLesson(payload);

            commit('SET_LESSON_LOADING_STATUS', false);
            commit('DELETE_LESSON', payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: 'That lesson has been removed.',
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            await router
                .replace({
                    name: 'lessons.summary',
                })
                .catch(() => {
                    //...
                });

            return await response;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: `${err.message}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_LESSON_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    setLesson({ commit }, payload) {
        commit('SET_LESSON', payload);
    },
};

export const getters = {
    lessons: (state) => {
        return state.lessons;
    },
    lesson: (state) => {
        return state.lesson;
    },
    lessonsAreLoading: (state) => {
        return state.lessonsAreLoading;
    },
    lessonIsLoading: (state) => {
        return state.lessonIsLoading;
    },
};
