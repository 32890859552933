import router from '@/router';

import Jsona from 'jsona';

import AssetService from '@/services/AssetService';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    assetIsLoading: false,
    assetsAreLoading: false,
    assets: [],
    asset: undefined,
};

export const mutations = {
    ADD_ASSET(state, payload) {
        state.assets.push(payload);
    },
    DELETE_ASSET(state, payload) {
        const index = state.assets.findIndex((value) => value.id === payload.id);
        state.assets.splice(index, 1);
    },
    SET_ASSET_LOADING_STATUS(state, payload) {
        state.assetIsLoading = payload;
    },
    SET_ASSETS_LOADING_STATUS(state, payload) {
        state.assetsAreLoading = payload;
    },
    SET_ASSETS(state, payload) {
        state.assets = payload;
    },
    SET_ASSET(state, payload) {
        state.asset = payload;
    },
};

export const actions = {
    async fetchAssets({ commit }, params) {
        try {
            commit('SET_ASSETS_LOADING_STATUS', true);

            const response = await AssetService.fetchAssets(params);
            const assets = dataFormatter.deserialize(response.data);

            commit('SET_ASSETS', assets);
            commit('SET_ASSETS_LOADING_STATUS', false);

            return assets;
        } catch (error) {
            commit('SET_ASSETS', []);
            commit('SET_ASSETS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async fetchAsset({ commit }, params) {
        try {
            commit('SET_ASSET_LOADING_STATUS', true);

            const response = await AssetService.fetchAsset(params);
            const asset = dataFormatter.deserialize(response.data);

            commit('SET_ASSET', asset);
            commit('SET_ASSET_LOADING_STATUS', false);

            return asset;
        } catch (error) {
            commit('SET_ASSET', undefined);
            commit('SET_ASSETS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async saveAsset({ commit, dispatch }, payload) {
        let { method, asset } = payload;

        try {
            commit('SET_ASSET_LOADING_STATUS', true);

            delete asset.links;

            const response = await AssetService.saveAsset(
                method,
                dataFormatter.serialize({
                    stuff: { ...asset, type: 'assets' },
                })
            );

            asset = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_ASSET', asset);
            }

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your asset has been ${method === 'POST' ? 'created' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_ASSET_LOADING_STATUS', false);

            return asset;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.message,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_ASSET_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async deleteAsset({ commit, dispatch }, payload) {
        try {
            commit('SET_ASSET_LOADING_STATUS', true);

            const response = await AssetService.deleteAsset(payload);

            commit('SET_ASSET_LOADING_STATUS', false);
            commit('DELETE_ASSET', payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: 'That asset has been removed.',
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            await router
                .replace({
                    name: 'assets.summary',
                })
                .catch(() => {
                    //...
                });

            return await response;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: `${err.message}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_ASSET_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    setAsset({ commit }, payload) {
        commit('SET_ASSET', payload);
    },
};

export const getters = {
    assets: (state) => {
        return state.assets;
    },
    asset: (state) => {
        return state.asset;
    },
    assetsAreLoading: (state) => {
        return state.assetsAreLoading;
    },
    assetIsLoading: (state) => {
        return state.assetIsLoading;
    },
};
