import router from '@/router';

import Jsona from 'jsona';

import LeagueService from '@/services/LeagueService';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    leagueIsLoading: false,
    leaguesAreLoading: false,
    leagues: [],
    league: undefined,
};

export const mutations = {
    ADD_LEAGUE(state, payload) {
        state.leagues.push(payload);
    },
    DELETE_LEAGUE(state, payload) {
        const index = state.leagues.findIndex((value) => value.id === payload.id);
        state.leagues.splice(index, 1);
    },
    SET_LEAGUE_LOADING_STATUS(state, payload) {
        state.leagueIsLoading = payload;
    },
    SET_LEAGUES_LOADING_STATUS(state, payload) {
        state.leaguesAreLoading = payload;
    },
    SET_LEAGUES(state, payload) {
        state.leagues = payload;
    },
    SET_LEAGUE(state, payload) {
        state.league = payload;
    },
};

export const actions = {
    async fetchLeagues({ commit }, params) {
        try {
            commit('SET_LEAGUES_LOADING_STATUS', true);

            const response = await LeagueService.fetchLeagues(params);
            const leagues = dataFormatter.deserialize(response.data);

            commit('SET_LEAGUES', leagues);
            commit('SET_LEAGUES_LOADING_STATUS', false);

            return leagues;
        } catch (error) {
            commit('SET_LEAGUES', []);
            commit('SET_LEAGUES_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async fetchLeague({ commit }, params) {
        try {
            commit('SET_LEAGUE_LOADING_STATUS', true);

            const response = await LeagueService.fetchLeague(params);
            const league = dataFormatter.deserialize(response.data);

            commit('SET_LEAGUE', league);
            commit('SET_LEAGUE_LOADING_STATUS', false);

            return league;
        } catch (error) {
            commit('SET_LEAGUE', undefined);
            commit('SET_LEAGUES_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async saveLeague({ commit, dispatch }, payload) {
        let { method, league } = payload;

        try {
            commit('SET_LEAGUE_LOADING_STATUS', true);

            delete league.links;

            const response = await LeagueService.saveLeague(
                method,
                dataFormatter.serialize({
                    stuff: { ...league, type: 'leagues' },
                })
            );

            league = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_LEAGUE', league);
            }

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your league has been ${method === 'POST' ? 'created' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_LEAGUE_LOADING_STATUS', false);

            return league;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.message,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_LEAGUE_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async deleteLeague({ commit, dispatch }, payload) {
        try {
            commit('SET_LEAGUE_LOADING_STATUS', true);

            const response = await LeagueService.deleteLeague(payload);

            commit('SET_LEAGUE_LOADING_STATUS', false);
            commit('DELETE_LEAGUE', payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: 'That league has been removed.',
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            await router
                .replace({
                    name: 'leagues.summary',
                })
                .catch(() => {
                    //...
                });

            return await response;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: `${err.message}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_LEAGUE_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    setLeague({ commit }, payload) {
        commit('SET_LEAGUE', payload);
    },
};

export const getters = {
    leagues: (state) => {
        return state.leagues;
    },
    league: (state) => {
        return state.league;
    },
    leaguesAreLoading: (state) => {
        return state.leaguesAreLoading;
    },
    leagueIsLoading: (state) => {
        return state.leagueIsLoading;
    },
};
