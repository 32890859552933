import client from '@/helpers/axios';

export default {
    async saveFile(file) {
        try {
            return await client.post(`/v1/files`, file, {
                headers: {
                    Accept: 'application/vnd.api+json',
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
