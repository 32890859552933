import Cookies from 'js-cookie';
import ConstantContactService from '@/services/ConstantContactService';

export const namespaced = true;

export const state = {
    accessToken: '',
    contactListsAreLoading: false,
    contactLists: [],
};

export const mutations = {
    SET_CONTACT_LISTS_LOADING_STATUS(state, payload) {
        state.contactListsAreLoading = payload;
    },
    SET_CONTACT_LISTS(state, payload) {
        state.contactLists = payload;
    },
    SET_ACCESS_TOKEN(state, payload) {
        state.accessToken = payload;

        if (payload) {
            Cookies.set('ccAccessToken', payload);
        } else {
            if (Cookies.get('ccAccessToken')) {
                Cookies.remove('ccAccessToken');
            }
        }
    },
};

export const actions = {
    async fetchContactLists({ commit }) {
        try {
            commit('SET_CONTACT_LISTS_LOADING_STATUS', true);

            const response = await ConstantContactService.fetchContactLists();

            commit('SET_CONTACT_LISTS', response.data.lists);
            commit('SET_CONTACT_LISTS_LOADING_STATUS', false);

            return response;
        } catch (error) {
            console.log('constantContact.fetchContactLists error: ', error);
            commit('SET_CONTACT_LISTS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async setAccessToken({ commit }, payload) {
        commit('SET_ACCESS_TOKEN', payload);
    },
};

export const getters = {
    accessToken: (state) => {
        return state.accessToken || Cookies.get('ccAccessToken');
    },
    contactLists: (state) => {
        return state.contactLists;
    },
};
