import router from '@/router';

import Jsona from 'jsona';
import Cookies from 'js-cookie';

import AuthenticationService from '@/services/AuthenticationService';
import UserService from '@/services/UserService';

const dataFormatter = new Jsona();
export const namespaced = true;

export const state = {
    authenticating: false,
    authenticatedUser: localStorage.getItem('authenticatedUser')
        ? JSON.parse(localStorage.getItem('authenticatedUser'))
        : undefined,
    accessToken: '',
    refreshToken: '',
    usersAreLoading: false,
    userIsLoading: false,
    emailVerificationIsLoading: false,
    phoneNumberVerificationIsLoading: false,
    user: undefined,
    users: [],
};

export const mutations = {
    SET_EMAIL_VERIFICATION_LOADING_STATUS(state, payload) {
        state.emailVerificationIsLoading = payload;
    },
    SET_PHONE_NUMBER_LOADING_STATUS(state, payload) {
        state.phoneNumberVerificationIsLoading = payload;
    },
    SET_AUTHENTICATION_LOADING_STATUS(state, payload) {
        state.authenticating = payload;
    },
    SET_ACCESS_TOKEN(state, payload) {
        state.accessToken = payload;

        if (payload) {
            Cookies.set('auth', payload);
        } else {
            if (Cookies.get('auth')) {
                Cookies.remove('auth');
            }
        }
    },
    SET_REFRESH_TOKEN(state, payload) {
        state.refreshToken = payload;

        if (payload) {
            Cookies.set('refresh', payload);
        } else {
            if (Cookies.get('refresh')) {
                Cookies.remove('refresh');
            }
        }
    },
    SET_AUTHENTICATED_USER(state, payload) {
        state.authenticatedUser = payload;

        if (payload) {
            localStorage.setItem('authenticatedUser', JSON.stringify(payload));
        } else {
            localStorage.removeItem('authenticatedUser');
        }
    },
    SET_USER_LOADING_STATUS(state, payload) {
        state.userIsLoading = payload;
    },
    SET_USERS_LOADING_STATUS(state, payload) {
        state.usersAreLoading = payload;
    },
    SET_USERS(state, payload) {
        state.users = payload;
    },
    SET_USER(state, payload) {
        state.user = payload;
    },
    ADD_USER(state, payload) {
        state.users.push(payload);
    },
    DELETE_USER(state, payload) {
        const index = state.users.findIndex((value) => value.id === payload.id);
        state.users.splice(index, 1);
    },
};

export const actions = {
    async authenticate({ commit, dispatch }, payload) {
        try {
            commit('SET_AUTHENTICATION_LOADING_STATUS', true);

            const rawAuthenticationResponse = await AuthenticationService.authenticate(payload);

            const authenticationResponse = dataFormatter.deserialize(
                rawAuthenticationResponse.data
            );

            if (authenticationResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
                await router
                    .replace({
                        name: 'auth.challenge',
                        query: {
                            session: authenticationResponse.session,
                            challengeName: 'NEW_PASSWORD_REQUIRED',
                        },
                    })
                    .catch(() => {
                        //...
                    });

                commit('SET_ACCESS_TOKEN', null);
                commit('SET_REFRESH_TOKEN', null);
                commit('SET_AUTHENTICATION_LOADING_STATUS', false);
            } else {
                const accessToken = authenticationResponse.authenticationResult.accessToken;
                const refreshToken = authenticationResponse.authenticationResult.refreshToken;

                commit('SET_ACCESS_TOKEN', accessToken);
                commit('SET_REFRESH_TOKEN', refreshToken);

                const rawAuthenticatedUser = await AuthenticationService.getAuthenticatedUser(
                    accessToken
                );

                const authenticatedUser = dataFormatter.deserialize(rawAuthenticatedUser.data);

                commit('SET_AUTHENTICATED_USER', authenticatedUser);

                dispatch('organization/setOrganization', authenticatedUser.organization, {
                    root: true,
                });

                await router
                    .replace({
                        name: 'app.dashboard',
                    })
                    .catch(() => {
                        //...
                    });

                commit('SET_AUTHENTICATION_LOADING_STATUS', false);
            }
        } catch (err) {
            commit('SET_AUTHENTICATION_LOADING_STATUS', false);
            commit('SET_AUTHENTICATED_USER', undefined);
            commit('SET_ACCESS_TOKEN', null);
            commit('SET_REFRESH_TOKEN', null);

            return await Promise.reject(err);
        }
    },
    async handleAuthChallenge({ commit, dispatch }, payload) {
        try {
            commit('SET_AUTHENTICATION_LOADING_STATUS', true);

            const rawAuthenticationResponse = await AuthenticationService.handleAuthChallenge(
                payload
            );

            const authenticationResponse = dataFormatter.deserialize(
                rawAuthenticationResponse.data
            );

            const accessToken = authenticationResponse.authenticationResult.accessToken;
            const refreshToken = authenticationResponse.authenticationResult.refreshToken;

            commit('SET_ACCESS_TOKEN', accessToken);
            commit('SET_REFRESH_TOKEN', refreshToken);

            const rawAuthenticatedUser = await AuthenticationService.getAuthenticatedUser(
                accessToken
            );

            const authenticatedUser = dataFormatter.deserialize(rawAuthenticatedUser.data);

            commit('SET_AUTHENTICATED_USER', authenticatedUser);

            dispatch('organization/setOrganization', authenticatedUser.organization, {
                root: true,
            });

            await router
                .replace({
                    name: 'app.dashboard',
                })
                .catch(() => {
                    //...
                });

            commit('SET_AUTHENTICATION_LOADING_STATUS', false);
        } catch (err) {
            commit('SET_AUTHENTICATION_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    async forgotPassword({ commit, dispatch }, payload) {
        try {
            commit('SET_AUTHENTICATION_LOADING_STATUS', true);

            await AuthenticationService.forgotPassword(payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Check your email for the verification code we'll need next.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            setTimeout(async () => {
                await router
                    .replace({
                        name: 'auth.reset',
                    })
                    .catch(() => {
                        //...
                    });
            }, 3000);

            commit('SET_AUTHENTICATION_LOADING_STATUS', false);
        } catch (err) {
            commit('SET_AUTHENTICATION_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    async resetPassword({ commit, dispatch }, payload) {
        try {
            commit('SET_AUTHENTICATION_LOADING_STATUS', true);

            await AuthenticationService.resetPassword(payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your password has been reset.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            setTimeout(async () => {
                await router
                    .replace({
                        name: 'auth.login',
                    })
                    .catch(() => {
                        //...
                    });
            }, 3000);

            commit('SET_AUTHENTICATION_LOADING_STATUS', false);
        } catch (err) {
            commit('SET_AUTHENTICATION_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    async signOut({ commit, dispatch }, payload) {
        commit('SET_AUTHENTICATION_LOADING_STATUS', true);

        if (payload) {
            await AuthenticationService.signOut(payload);
        }

        commit('SET_AUTHENTICATION_LOADING_STATUS', false);
        commit('SET_AUTHENTICATED_USER', undefined);
        commit('SET_ACCESS_TOKEN', '');
        commit('SET_REFRESH_TOKEN', '');

        dispatch('organization/setOrganization', undefined, {
            root: true,
        });

        dispatch('location/setLocation', undefined, {
            root: true,
        });

        await router
            .replace({
                name: 'auth.login',
            })
            .catch(() => {
                //...
            });

        commit('SET_AUTHENTICATION_LOADING_STATUS', false);
    },
    async fetchUsers({ commit }, params) {
        try {
            commit('SET_USERS_LOADING_STATUS', true);

            const response = await UserService.fetchUsers(params);
            const users = dataFormatter.deserialize(response.data);

            commit('SET_USERS', users);
            commit('SET_USERS_LOADING_STATUS', false);

            return users;
        } catch (err) {
            commit('SET_USERS', []);
            commit('SET_USERS_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    async fetchUser({ commit }, params) {
        commit('SET_USER_LOADING_STATUS', true);

        try {
            const response = await UserService.fetchUser(params);
            const user = dataFormatter.deserialize(response.data);

            commit('SET_USER', user);
            commit('SET_USER_LOADING_STATUS', false);

            return await user;
        } catch (err) {
            commit('SET_USER', undefined);
            commit('SET_USERS_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },

    async saveUser({ commit, dispatch }, payload) {
        let { method, organizationId, user } = payload;

        try {
            commit('SET_USER_LOADING_STATUS', true);

            user.organizationId = organizationId;
            user.phoneNumber = user.phoneNumber.replace(/[^+\d]+/g, '');
            user.relationshipNames = ['userGroups'];

            delete user.links;

            const response = await UserService.saveUser(
                method,
                dataFormatter.serialize({
                    stuff: {
                        ...user,
                        type: 'users',
                    },
                })
            );

            user = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_USER', user);
            }

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `That user has been ${method === 'POST' ? 'created' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_USER_LOADING_STATUS', false);

            return user;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.response.data.errors[0].detail,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_USER_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async deleteUser({ commit, dispatch }, payload) {
        try {
            commit('SET_USER_LOADING_STATUS', true);

            const response = await UserService.deleteUser(payload);

            commit('SET_USER_LOADING_STATUS', false);
            commit('DELETE_USER', payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: 'That user has been removed.',
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            await router
                .replace({
                    name: 'users.summary',
                })
                .catch(() => {
                    //...
                });

            return await response;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: `${err.response.data.errors[0].detail}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_USER_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    async generateAttributeCode({ commit, dispatch }, payload) {
        const attribute = payload.attributeName;

        try {
            if (attribute === 'email') {
                commit('SET_EMAIL_VERIFICATION_LOADING_STATUS', true);
            }

            if (attribute === 'phone_number') {
                commit('SET_PHONE_NUMBER_LOADING_STATUS', true);
            }

            const response = await AuthenticationService.generateAttributeCode(
                dataFormatter.serialize({
                    stuff: {
                        ...payload,
                        type: 'attribute-codes',
                    },
                })
            );

            const result = dataFormatter.deserialize(response.data);

            if (attribute === 'email') {
                commit('SET_EMAIL_VERIFICATION_LOADING_STATUS', false);
            }

            if (attribute === 'phone_number') {
                commit('SET_PHONE_NUMBER_LOADING_STATUS', false);
            }

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Check your ${attribute} for a verification code.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            return result;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.response.data.errors[0].detail,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            if (attribute === 'email') {
                commit('SET_EMAIL_VERIFICATION_LOADING_STATUS', false);
            }

            if (attribute === 'phone_number') {
                commit('SET_PHONE_NUMBER_LOADING_STATUS', false);
            }

            return Promise.reject(err);
        }
    },
    async verifyUserAttribute({ commit, dispatch }, payload) {
        const attribute = payload.attributeName;

        try {
            if (attribute === 'email') {
                commit('SET_EMAIL_VERIFICATION_LOADING_STATUS', true);
            }

            if (attribute === 'phone_number') {
                commit('SET_PHONE_NUMBER_LOADING_STATUS', true);
            }

            await AuthenticationService.verifyUserAttribute(
                dataFormatter.serialize({
                    stuff: {
                        ...payload,
                        type: 'attribute-codes',
                    },
                })
            );

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your ${
                    attribute === 'email' ? 'email' : 'phone number'
                } has been verified.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            if (attribute === 'email') {
                commit('SET_EMAIL_VERIFICATION_LOADING_STATUS', false);
            }

            if (attribute === 'phone_number') {
                commit('SET_PHONE_NUMBER_LOADING_STATUS', false);
            }
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            if (attribute === 'email') {
                commit('SET_EMAIL_VERIFICATION_LOADING_STATUS', false);
            }

            if (attribute === 'phone_number') {
                commit('SET_PHONE_NUMBER_LOADING_STATUS', false);
            }

            return Promise.reject(err);
        }
    },
    async refreshAccessToken({ commit }, payload) {
        try {
            const response = await AuthenticationService.refreshAccessToken(payload);
            const tokens = dataFormatter.deserialize(response.data);

            commit('SET_ACCESS_TOKEN', tokens.authenticationResult.accessToken);
            commit('SET_REFRESH_TOKEN', tokens.authenticationResult.refreshToken);

            return response;
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async setUser({ commit }, payload) {
        commit('SET_USER', payload);
    },
};

export const getters = {
    authenticating: (state) => {
        return state.authenticating;
    },
    authenticatedUser: (state) => {
        return state.authenticatedUser;
    },
    isAuthenticated: (state) => {
        return state.isAuthenticated;
    },
    accessToken: (state) => {
        return state.accessToken || Cookies.get('auth');
    },
    refreshToken: (state) => {
        return state.refreshToken || Cookies.get('refresh');
    },
    usersAreLoading: (state) => {
        return state.usersAreLoading;
    },
    userIsLoading: (state) => {
        return state.userIsLoading;
    },
    emailVerificationIsLoading: (state) => {
        return state.emailVerificationIsLoading;
    },
    phoneNumberVerificationIsLoading: (state) => {
        return state.phoneNumberVerificationIsLoading;
    },
    users: (state) => {
        return state.users;
    },
    user: (state) => {
        return state.user;
    },
};
