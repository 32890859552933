import client from '@/helpers/axios';

export default {
    async saveOrder(method, order) {
        if (method === 'PATCH') {
            try {
                return await client.patch(`/v1/orders/${order.data.id}`, order);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/orders`, order);
            } catch (err) {
                return await Promise.reject(err);
            }
        }
    },
    async fetchOrders(payload) {
        const { page, limit } = payload;

        try {
            return await client.get(`/v1/orders?page[number]=${page}&page[size]=${limit}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async fetchOrder(params) {
        const { orderId } = params;

        try {
            return await client.get(`/v1/orders/${orderId}?include=reservations`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async deleteOrder(orderId) {
        try {
            return await client.delete(`/v1/orders/${orderId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
