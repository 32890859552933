import qs from 'qs';
import VueJwtDecode from 'vue-jwt-decode';

import client from '@/helpers/axios';

export default {
    async authenticate(payload) {
        try {
            return await client.post('/v1/tokens', payload);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async forgotPassword(payload) {
        try {
            return await client.post(`/v1/auth/forgot`, payload);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async resetPassword(payload) {
        try {
            return await client.post(`/v1/auth/reset`, payload);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async generateAttributeCode(payload) {
        try {
            return await client.post(`/v1/auth/generate`, payload);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async verifyUserAttribute(payload) {
        try {
            return await client.post(`/v1/auth/verify`, payload);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async handleAuthChallenge(payload) {
        try {
            return await client.post(`/v1/auth/challenge`, payload);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async getAuthenticatedUser(accessToken) {
        const token = VueJwtDecode.decode(accessToken);

        try {
            return await client.get(`/v1/users/${token.username}?include=organization,userGroups`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async refreshAccessToken(payload) {
        try {
            return await client.post(
                `/v1/auth/refresh`,
                qs.stringify({
                    refreshToken: payload.refreshToken,
                    userId: payload.user.id,
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async signOut(payload) {
        try {
            return await client.post(`/v1/auth/logout`, {
                data: {
                    type: 'tokens',
                    attributes: {
                        username: payload.userId,
                    },
                },
            });
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
