import router from '@/router';

import Jsona from 'jsona';

import EventService from '@/services/EventService';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    eventIsLoading: false,
    eventsAreLoading: false,
    events: [],
    event: undefined,
};

export const mutations = {
    ADD_EVENT(state, payload) {
        state.events.push(payload);
    },
    DELETE_EVENT(state, payload) {
        const index = state.events.findIndex((value) => value.id === payload.id);
        state.events.splice(index, 1);
    },
    SET_EVENT_LOADING_STATUS(state, payload) {
        state.eventIsLoading = payload;
    },
    SET_EVENTS_LOADING_STATUS(state, payload) {
        state.eventsAreLoading = payload;
    },
    SET_EVENTS(state, payload) {
        state.events = payload;
    },
    SET_EVENT(state, payload) {
        state.event = payload;
    },
};

export const actions = {
    async fetchEvents({ commit }, params) {
        try {
            commit('SET_EVENTS_LOADING_STATUS', true);

            const response = await EventService.fetchEvents(params);
            const events = dataFormatter.deserialize(response.data);

            commit('SET_EVENTS', events);
            commit('SET_EVENTS_LOADING_STATUS', false);

            return events;
        } catch (error) {
            commit('SET_EVENTS', []);
            commit('SET_EVENTS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async fetchEvent({ commit }, params) {
        try {
            commit('SET_EVENT_LOADING_STATUS', true);

            const response = await EventService.fetchEvent(params);
            const event = dataFormatter.deserialize(response.data);

            commit('SET_EVENT', event);
            commit('SET_EVENT_LOADING_STATUS', false);

            return event;
        } catch (error) {
            commit('SET_EVENT', undefined);
            commit('SET_EVENTS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async saveEvent({ commit, dispatch }, payload) {
        let { method, event, organizationId, locationId } = payload;

        try {
            commit('SET_EVENT_LOADING_STATUS', true);

            event.organizationId = organizationId;
            event.locationId = locationId;
            event.relationshipNames = ['reservables'];

            delete event.links;
            delete event.location;

            const response = await EventService.saveEvent(
                method,
                dataFormatter.serialize({
                    stuff: { ...event, type: 'events' },
                })
            );

            event = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_EVENT', event);
            }

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your event has been ${method === 'POST' ? 'created' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_EVENT_LOADING_STATUS', false);

            return event;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.message,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_EVENT_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async deleteEvent({ commit, dispatch }, payload) {
        try {
            commit('SET_EVENT_LOADING_STATUS', true);

            const response = await EventService.deleteEvent(payload);

            commit('SET_EVENT_LOADING_STATUS', false);
            commit('DELETE_EVENT', payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: 'That event has been removed.',
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            await router
                .replace({
                    name: 'events.summary',
                })
                .catch(() => {
                    //...
                });

            return await response;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: `${err.message}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_EVENT_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    setEvent({ commit }, payload) {
        commit('SET_EVENT', payload);
    },
};

export const getters = {
    events: (state) => {
        return state.events;
    },
    event: (state) => {
        return state.event;
    },
    eventsAreLoading: (state) => {
        return state.eventsAreLoading;
    },
    eventIsLoading: (state) => {
        return state.eventIsLoading;
    },
};
