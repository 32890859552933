import client from '@/helpers/axios';

export default {
    async saveEvent(method, event) {
        if (method === 'PATCH') {
            try {
                return await client.patch(`/v1/events/${event.data.id}`, event);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/events`, event);
            } catch (err) {
                return await Promise.reject(err);
            }
        }
    },
    async fetchEvents() {
        try {
            return await client.get(`/v1/events`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async fetchEvent(params) {
        const { eventId } = params;

        try {
            return await client.get(`/v1/events/${eventId}?include=location,reservables`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async deleteEvent(eventId) {
        try {
            return await client.delete(`/v1/events/${eventId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
