<template>
    <div>
        <div
            class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
        >
            <div class="sm:flex sm:items-start">
                <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-yellow-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                    </svg>
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 id="modal-title" class="text-lg font-medium leading-6 text-gray-900">
                        Account verification
                    </h3>
                    <div class="mt-2">
                        <transition-group
                            enter-active-class="transition-opacity ease-in-out duration-75"
                            enter-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="transition-opacity ease-in-out duration-75"
                            leave-class="opacity-100"
                            leave-to-class="opacity-0"
                            mode="out-in"
                        >
                            <!-- step 1 -->
                            <div v-if="currentStep === 1" key="step-1">
                                <p class="text-sm text-gray-500">
                                    <strong>Your account has not been verified.</strong><br />
                                    We won't be able to send you helpful email(s) such as reset
                                    password until your email has been verified.
                                </p>
                            </div>
                            <!-- /step 1 -->

                            <!-- step 2 -->
                            <div v-if="currentStep === 2" key="step-2" class="w-full">
                                <p class="text-sm text-gray-500">
                                    Check your email for a verification code&mdash;you'll need that
                                    in the step&nbsp; below.
                                </p>
                                <form class="mt-6 space-y-6">
                                    <!-- emailVerificationCode -->
                                    <div>
                                        <div
                                            :class="[
                                                'relative rounded-md border px-3 py-2 shadow-sm transition duration-75 ease-linear focus-within:ring-1',
                                                $v.emailVerificationCode.$error
                                                    ? 'border-red-300 focus-within:border-red-600 focus-within:ring-red-600'
                                                    : 'border-gray-300 focus-within:border-gray-600 focus-within:ring-gray-600',
                                            ]"
                                        >
                                            <label
                                                for="email"
                                                :class="[
                                                    'block text-xs font-medium',
                                                    $v.emailVerificationCode.$error
                                                        ? 'text-red-700'
                                                        : 'text-gray-900',
                                                ]"
                                                >Verification code</label
                                            >
                                            <input
                                                id="code"
                                                v-model.trim="$v.emailVerificationCode.$model"
                                                type="text"
                                                name="code"
                                                autocomplete="email"
                                                :class="[
                                                    'block w-full border-0 p-0 transition duration-75 ease-linear focus:ring-0 sm:text-sm',
                                                    $v.emailVerificationCode.$error
                                                        ? 'text-red-900 placeholder-red-300'
                                                        : 'text-gray-900 placeholder-gray-300',
                                                ]"
                                                placeholder="123456"
                                                required
                                            />
                                            <transition
                                                enter-active-class="transition-opacity ease-linear duration-75"
                                                enter-class="opacity-0"
                                                enter-to-class="opacity-100"
                                                leave-active-class="transition-opacity ease-linear duration-75"
                                                leave-class="opacity-100"
                                                leave-to-class="opacity-0"
                                            >
                                                <div
                                                    v-if="$v.emailVerificationCode.$error"
                                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                                >
                                                    <svg
                                                        class="h-5 w-5 text-red-500"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                                            clip-rule="evenodd"
                                                        />
                                                    </svg>
                                                </div>
                                            </transition>
                                        </div>
                                        <transition
                                            enter-active-class="transition-opacity ease-linear duration-75"
                                            enter-class="opacity-0"
                                            enter-to-class="opacity-100"
                                            leave-active-class="transition-opacity ease-linear duration-75"
                                            leave-class="opacity-100"
                                            leave-to-class="opacity-0"
                                        >
                                            <p
                                                v-if="$v.emailVerificationCode.$error"
                                                key="required"
                                                class="mt-2 text-sm font-medium text-red-600"
                                            >
                                                This field is required
                                            </p>
                                        </transition>
                                    </div>
                                    <!-- /code -->
                                </form>
                            </div>
                            <!-- /step 2 -->

                            <!-- step 3 -->
                            <div v-if="currentStep === 3" key="step-3">
                                <p class="text-sm text-gray-500">
                                    Awesome! Your email address has been verified. You can close
                                    this modal.
                                </p>
                            </div>
                            <!-- /step 3 -->
                        </transition-group>
                    </div>
                </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <!-- cancel -->
                <button
                    v-if="shouldShowLaterButton"
                    type="button"
                    class="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm transition duration-75 ease-linear hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:cursor-not-allowed"
                    @click="cancel"
                >
                    Maybe later
                </button>
                <!-- /cancel -->

                <!-- request code -->
                <button
                    v-if="currentStep === 1"
                    data-cy="submit-button"
                    type="submit"
                    :class="[
                        'ml-4 inline-flex justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm transition duration-75 ease-linear focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-700 disabled:opacity-60',
                        emailVerificationIsLoading
                            ? 'cursor-not-allowed hover:bg-gray-600'
                            : 'hover:bg-gray-700',
                    ]"
                    @click.prevent="generateAttributeCode"
                >
                    <svg
                        v-if="emailVerificationIsLoading"
                        key="loading-indicator"
                        data-cy="loading-indicator"
                        class="-ml-1 mr-3 h-5 w-5 animate-spin text-gray-700"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                    <span v-if="emailVerificationIsLoading" key="processing">Please wait...</span>
                    <span v-else key="text">Send verification code</span>
                </button>
                <!-- /request code -->

                <!-- verify -->
                <button
                    v-if="currentStep === 2"
                    data-cy="submit-button"
                    type="submit"
                    :disabled="shouldDisableSubmit"
                    :class="[
                        'ml-4 inline-flex justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm transition duration-75 ease-linear focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-700 disabled:opacity-60',
                        emailVerificationIsLoading
                            ? 'cursor-not-allowed hover:bg-gray-600'
                            : 'hover:bg-gray-700',
                    ]"
                    @click.prevent="verifyEmailAddress"
                >
                    <svg
                        v-if="emailVerificationIsLoading"
                        key="loading-indicator"
                        data-cy="loading-indicator"
                        class="-ml-1 mr-3 h-5 w-5 animate-spin text-gray-700"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                    <span v-if="emailVerificationIsLoading" key="processing">Please wait...</span>
                    <span v-else key="text">Verify email address</span>
                </button>
                <!-- /verify -->

                <!-- close -->
                <button
                    v-if="currentStep === 3"
                    data-cy="submit-button"
                    type="button"
                    class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm transition duration-75 ease-linear hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-700 disabled:opacity-60"
                    @click="cancel"
                >
                    Close
                </button>
                <!-- /close -->
            </div>
        </div>
    </div>
</template>

<script lang="js">
import { required } from 'vuelidate/lib/validators';
import Cookies from 'js-cookie';

export default {
  name: 'VerifyEmailModal',
  validations: {
    emailVerificationCode: {
      required,
    },
  },
  data() {
    return {
      emailVerificationCode: '',
      currentStep: 1,
    }
  },
  computed: {
    authenticating() {
      return this.$store.getters['user/authenticating'];
    },
    shouldDisableSubmit() {
      return this.authenticating || this.$v.$invalid;
    },
    shouldShowLaterButton() {
      return this.currentStep === 1 || this.currentStep === 2;
    },
    emailVerificationIsLoading() {
      return this.$store.getters['user/emailVerificationIsLoading'];
    },
    authenticatedUser() {
      return this.$store.getters['user/authenticatedUser'];
    }
  },
  methods: {
    async verifyEmailAddress() {
      this.$v.emailVerificationCode.$touch();

      if (!this.$v.emailVerificationCode.$invalid) {
        const payload = {
          attributeName: 'email',
          accessToken: Cookies.get('auth'),
          verificationCode: this.$v.emailVerificationCode.$model,
        }

        try {
          await this.$store.dispatch('user/verifyUserAttribute', payload);

          this.authenticatedUser.emailVerified = true;
          this.currentStep = 3;
        } catch (err) {
          return await Promise.reject(err);
        }
      }
    },
    async generateAttributeCode() {
      try {
        await this.$store.dispatch('user/generateAttributeCode', {
          attributeName: 'email',
          accessToken: Cookies.get('auth'),
        });

        this.currentStep = 2;
      } catch (err) {
        return await Promise.reject(err);
      }
    },
    async cancel() {
      await this.$store.dispatch('modal/closeModal');
    }
  }
}
</script>
