import client from '@/helpers/axios';

import Cookies from 'js-cookie';

const setup = (store) => {
    client.interceptors.request.use(
        (config) => {
            const token = Cookies.get('auth');

            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    client.interceptors.response.use(
        (response) => {
            return response;
        },
        async (err) => {
            const {
                config,
                response: { status },
            } = err;

            if (config.url !== '/auth/signin' && err.response.data.errors[0].detail) {
                if (status === 401 && !config._retry) {
                    config._retry = true;
                    try {
                        console.log('trying to refresh the token...');
                        const refreshedToken = await store.dispatch(
                            'user/refreshAccessToken',
                            store.getters['user/refreshToken']
                        );
                        console.log('refreshedToken: ', refreshedToken);
                        return client(config);
                    } catch (err) {
                        return await Promise.reject(err);
                    }
                }
            }
            return Promise.reject(err);
        }
    );
};

export default setup;
