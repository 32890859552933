import client from '@/helpers/axios';

export default {
    async saveLesson(method, lesson) {
        if (method === 'PATCH') {
            try {
                return await client.patch(`/v1/lessons/${lesson.data.id}`, lesson);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/lessons`, lesson);
            } catch (err) {
                return await Promise.reject(err);
            }
        }
    },
    async fetchLessons() {
        try {
            return await client.get(`/v1/lessons`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async fetchLesson(params) {
        const { lessonId } = params;

        try {
            return await client.get(`/v1/lessons/${lessonId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async deleteLesson(lessonId) {
        try {
            return await client.delete(`/v1/lessons/${lessonId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
