import router from '@/router';

import Jsona from 'jsona';

import UserGroupService from '@/services/UserGroupService';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    userGroupIsLoading: false,
    userGroupsAreLoading: false,
    userGroups: [],
    userGroup: undefined,
};

export const mutations = {
    ADD_USER_GROUP(state, payload) {
        state.userGroups.push(payload);
    },
    DELETE_USER_GROUP(state, payload) {
        const index = state.userGroups.findIndex((value) => value.id === payload.id);
        state.userGroups.splice(index, 1);
    },
    SET_USER_GROUP_LOADING_STATUS(state, payload) {
        state.userGroupIsLoading = payload;
    },
    SET_USER_GROUPS_LOADING_STATUS(state, payload) {
        state.userGroupsAreLoading = payload;
    },
    SET_USER_GROUPS(state, payload) {
        state.userGroups = payload;
    },
    SET_USER_GROUP(state, payload) {
        state.userGroup = payload;
    },
};

export const actions = {
    async fetchUserGroups({ commit }, params) {
        try {
            commit('SET_USER_GROUPS_LOADING_STATUS', true);

            const response = await UserGroupService.fetchUserGroups(params);
            const userGroups = dataFormatter.deserialize(response.data);

            commit('SET_USER_GROUPS', userGroups);
            commit('SET_USER_GROUPS_LOADING_STATUS', false);

            return userGroups;
        } catch (error) {
            commit('SET_USER_GROUPS', []);
            commit('SET_USER_GROUPS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async fetchUserGroup({ commit }, params) {
        try {
            commit('SET_USER_GROUP_LOADING_STATUS', true);

            const response = await UserGroupService.fetchUserGroup(params);
            const userGroup = dataFormatter.deserialize(response.data);

            commit('SET_USER_GROUP', userGroup);
            commit('SET_USER_GROUP_LOADING_STATUS', false);

            return userGroup;
        } catch (error) {
            commit('SET_USER_GROUP', undefined);
            commit('SET_USER_GROUPS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async saveUserGroup({ commit, dispatch }, payload) {
        let { method, userGroup } = payload;

        try {
            commit('SET_USER_GROUP_LOADING_STATUS', true);

            delete userGroup.links;

            const response = await UserGroupService.saveUserGroup(
                method,
                dataFormatter.serialize({
                    stuff: { ...userGroup, type: 'user-groups' },
                })
            );

            userGroup = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_USER_GROUP', userGroup);
            }

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your group has been ${method === 'POST' ? 'created' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_USER_GROUP_LOADING_STATUS', false);

            return userGroup;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.message,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_USER_GROUP_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async deleteUserGroup({ commit, dispatch }, payload) {
        try {
            commit('SET_USER_GROUP_LOADING_STATUS', true);

            const response = await UserGroupService.deleteUserGroup(payload);

            commit('SET_USER_GROUP_LOADING_STATUS', false);
            commit('DELETE_USER_GROUP', payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: 'That user group has been removed.',
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            await router
                .replace({
                    name: 'userGroups.summary',
                })
                .catch(() => {
                    //...
                });

            return await response;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: `${err.message}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_USER_GROUP_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    setUserGroup({ commit }, payload) {
        commit('SET_USER_GROUP', payload);
    },
};

export const getters = {
    userGroups: (state) => {
        return state.userGroups;
    },
    userGroup: (state) => {
        return state.userGroup;
    },
    userGroupsAreLoading: (state) => {
        return state.userGroupsAreLoading;
    },
    userGroupIsLoading: (state) => {
        return state.userGroupIsLoading;
    },
};
