export const namespaced = true;

export const state = {
    vendorIsLoading: false,
    vendor: undefined,
};

export const mutations = {
    SET_VENDOR_LOADING_STATUS(state, payload) {
        state.vendorIsLoading = payload;
    },
    SET_VENDOR(state, payload) {
        state.vendor = payload;
    },
};

export const actions = {
    setVendor({ commit }, payload) {
        commit('SET_VENDOR', payload);
    },
};

export const getters = {
    vendor: (state) => {
        return state.vendor;
    },
    vendorIsLoading: (state) => {
        return state.vendorIsLoading;
    },
};
