import client from '@/helpers/axios';

export default {
    async saveActivity(method, activity) {
        if (method === 'PATCH') {
            try {
                return await client.patch(`/v1/activities/${activity.data.id}`, activity);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/activities`, activity);
            } catch (err) {
                return await Promise.reject(err);
            }
        }
    },
    async fetchActivities() {
        try {
            return await client.get(`/v1/activities`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async fetchActivity(params) {
        const { activityId } = params;

        try {
            return await client.get(`/v1/activities/${activityId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async deleteActivity(activityId) {
        try {
            return await client.delete(`/v1/activities/${activityId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
