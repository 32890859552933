import client from '@/helpers/axios';

export default {
    async saveTicket(method, ticket) {
        if (method === 'PATCH') {
            try {
                return await client.patch(`/v1/tickets/${ticket.data.id}`, ticket);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/tickets`, ticket);
            } catch (err) {
                return await Promise.reject(err);
            }
        }
    },
    async fetchTickets() {
        try {
            return await client.get(`/v1/tickets`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async fetchTicket(params) {
        const { ticketId } = params;

        try {
            return await client.get(`/v1/tickets/${ticketId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async deleteTicket(ticketId) {
        try {
            return await client.delete(`/v1/tickets/${ticketId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
