import client from '@/helpers/axios';

export default {
    async saveReservable(method, reservable) {
        if (method === 'PATCH') {
            try {
                return await client.patch(`/v1/reservables/${reservable.data.id}`, reservable);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/reservables`, reservable);
            } catch (err) {
                return await Promise.reject(err);
            }
        }
    },
    async fetchReservables(params) {
        const { locationId } = params;

        try {
            return await client.get(`/v1/locations/${locationId}/reservables`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async fetchReservable(params) {
        const { reservableId } = params;

        try {
            return await client.get(`/v1/reservables/${reservableId}?include=activities`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async deleteReservable(reservableId) {
        try {
            return await client.delete(`/v1/reservables/${reservableId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
