import client from '@/helpers/axios';

export default {
    async saveAccessory(method, accessory) {
        if (method === 'PATCH') {
            try {
                return await client.patch(`/v1/accessories/${accessory.data.id}`, accessory);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/accessories`, accessory);
            } catch (err) {
                return await Promise.reject(err);
            }
        }
    },
    async fetchAccessories() {
        try {
            return await client.get(`/v1/accessories`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async fetchAccessory(params) {
        const { accessoryId } = params;

        try {
            return await client.get(`/v1/accessories/${accessoryId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async deleteAccessory(accessoryId) {
        try {
            return await client.delete(`/v1/accessories/${accessoryId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
