import router from '@/router';

import Jsona from 'jsona';

import FileService from '@/services/FileService';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    fileIsLoading: false,
    filesAreLoading: false,
    files: [],
    file: undefined,
};

export const mutations = {
    ADD_FILE(state, payload) {
        state.files.push(payload);
    },
    DELETE_FILE(state, payload) {
        const index = state.files.findIndex((value) => value.id === payload.id);
        state.files.splice(index, 1);
    },
    SET_FILE_LOADING_STATUS(state, payload) {
        state.fileIsLoading = payload;
    },
    SET_FILES_LOADING_STATUS(state, payload) {
        state.filesAreLoading = payload;
    },
    SET_FILES(state, payload) {
        state.files = payload;
    },
    SET_FILE(state, payload) {
        state.file = payload;
    },
};

export const actions = {
    async fetchFiles({ commit }, params) {
        try {
            commit('SET_FILES_LOADING_STATUS', true);

            const response = await FileService.fetchFiles(params);
            const files = dataFormatter.deserialize(response.data);

            commit('SET_FILES', files);
            commit('SET_FILES_LOADING_STATUS', false);

            return files;
        } catch (error) {
            commit('SET_FILES', []);
            commit('SET_FILES_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async fetchFile({ commit }, params) {
        try {
            commit('SET_FILE_LOADING_STATUS', true);

            const response = await FileService.fetchFile(params);
            const file = dataFormatter.deserialize(response.data);

            commit('SET_FILE', file);
            commit('SET_FILE_LOADING_STATUS', false);

            return file;
        } catch (error) {
            commit('SET_FILE', undefined);
            commit('SET_FILES_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async saveFile({ commit, dispatch }, payload) {
        try {
            commit('SET_FILE_LOADING_STATUS', true);

            const file = await FileService.saveFile(payload);

            commit('SET_FILE_LOADING_STATUS', false);

            return file;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.message,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_FILE_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async deleteFile({ commit, dispatch }, payload) {
        try {
            commit('SET_FILE_LOADING_STATUS', true);

            const response = await FileService.deleteFile(payload);

            commit('SET_FILE_LOADING_STATUS', false);
            commit('DELETE_FILE', payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: 'That file has been removed.',
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            await router
                .replace({
                    name: 'files.summary',
                })
                .catch(() => {
                    //...
                });

            return await response;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: `${err.message}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_FILE_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    setFile({ commit }, payload) {
        commit('SET_FILE', payload);
    },
};

export const getters = {
    files: (state) => {
        return state.files;
    },
    file: (state) => {
        return state.file;
    },
    filesAreLoading: (state) => {
        return state.filesAreLoading;
    },
    fileIsLoading: (state) => {
        return state.fileIsLoading;
    },
};
