import Jsona from 'jsona';

import AccessoryService from '@/services/AccessoryService';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    accessoryIsLoading: false,
    accessoriesAreLoading: false,
    accessories: [],
    accessory: undefined,
};

export const mutations = {
    ADD_ACCESSORY(state, payload) {
        state.accessories.push(payload);
    },
    DELETE_ACCESSORY(state, payload) {
        const index = state.accessories.findIndex((value) => value.id === payload.id);
        state.accessories.splice(index, 1);
    },
    SET_ACCESSORY_LOADING_STATUS(state, payload) {
        state.accessoryIsLoading = payload;
    },
    SET_ACCESSORIES_LOADING_STATUS(state, payload) {
        state.accessoriesAreLoading = payload;
    },
    SET_ACCESSORIES(state, payload) {
        state.accessories = payload;
    },
    SET_ACCESSORY(state, payload) {
        state.accessory = payload;
    },
};

export const actions = {
    async fetchAccessories({ commit }, params) {
        try {
            commit('SET_ACCESSORIES_LOADING_STATUS', true);

            const response = await AccessoryService.fetchAccessories(params);
            const accessories = dataFormatter.deserialize(response.data);

            commit('SET_ACCESSORIES', accessories);
            commit('SET_ACCESSORIES_LOADING_STATUS', false);

            return accessories;
        } catch (error) {
            commit('SET_ACCESSORIES', []);
            commit('SET_ACCESSORIES_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async fetchAccessory({ commit }, params) {
        try {
            commit('SET_ACCESSORY_LOADING_STATUS', true);

            const response = await AccessoryService.fetchAccessory(params);
            const accessory = dataFormatter.deserialize(response.data);

            commit('SET_ACCESSORY', accessory);
            commit('SET_ACCESSORY_LOADING_STATUS', false);

            return accessory;
        } catch (error) {
            commit('SET_ACCESSORY', undefined);
            commit('SET_ACCESSORIES_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async saveAccessory({ commit, dispatch }, payload) {
        let { method, accessory, organizationId, locationId, reservableId } = payload;

        try {
            commit('SET_ACCESSORY_LOADING_STATUS', true);

            accessory.organizationId = organizationId;
            accessory.locationId = locationId;
            accessory.reservableId = reservableId;

            delete accessory.links;

            const response = await AccessoryService.saveAccessory(
                method,
                dataFormatter.serialize({
                    stuff: { ...accessory, type: 'accessories' },
                })
            );

            accessory = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_ACCESSORY', accessory);
            }

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your accessory has been ${method === 'POST' ? 'created' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_ACCESSORY_LOADING_STATUS', false);

            return accessory;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.message,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_ACCESSORY_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    setAccessory({ commit }, payload) {
        commit('SET_ACCESSORY', payload);
    },
};

export const getters = {
    accessories: (state) => {
        return state.accessories;
    },
    accessory: (state) => {
        return state.accessory;
    },
    accessoriesAreLoading: (state) => {
        return state.accessoriesAreLoading;
    },
    accessoryIsLoading: (state) => {
        return state.accessoryIsLoading;
    },
};
