import client from '@/helpers/axios';

export default {
    async saveArtifact(method, artifact) {
        if (method === 'PATCH') {
            try {
                return await client.patch(`/v1/artifacts/${artifact.data.id}`, artifact);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/artifacts`, artifact);
            } catch (err) {
                return await Promise.reject(err);
            }
        }
    },
    async fetchArtifacts() {
        try {
            return await client.get(`/v1/artifacts`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async fetchArtifact(params) {
        const { artifactId } = params;

        try {
            return await client.get(`/v1/artifacts/${artifactId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async deleteArtifact(artifactId) {
        try {
            return await client.delete(`/v1/artifacts/${artifactId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
