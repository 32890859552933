import client from '@/helpers/axios';

export default {
    async requestSupport(payload) {
        const { name, email, reason, message } = payload.support;
        const support = {};

        support.name = name;
        support.email = email;
        support.reason = reason;
        support.message = message;

        let method = payload.method;

        if (method === 'POST') {
            try {
                return await client.post(`/v1/support`, payload.support);
            } catch (err) {
                return await Promise.reject(err);
            }
        }
    },
};
