<template>
    <div class="flex h-full">
        <!-- application navigation -->
        <transition
            enter-active-class="transition-opacity ease-linear duration-25"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity ease-linear duration-25"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
            mode="out-in"
        >
            <AppNavigation v-if="authenticatedUser" />
        </transition>
        <!-- /application navigation -->

        <div class="flex min-w-0 flex-1 flex-col overflow-hidden">
            <!-- main router output -->
            <transition
                enter-active-class="transition-opacity ease-linear duration-25"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition-opacity ease-linear duration-25"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
                mode="out-in"
            >
                <router-view />
            </transition>
            <!-- /main router output -->
        </div>

        <!-- notifications -->
        <div
            aria-live="assertive"
            class="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
        >
            <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
                <transition
                    enter-active-class="transform ease-out duration-300 transition"
                    enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                    leave-active-class="transition ease-in duration-100"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <StatusNotification
                        v-for="notification in notifications"
                        :key="notification.id"
                        :notification="notification"
                    />
                </transition>
            </div>
        </div>
        <!-- /notifications -->

        <!-- modals -->
        <div
            v-if="authenticatedUser"
            :class="modal ? 'relative z-10' : 'absolute h-0 w-0'"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <transition
                enter-active-class="ease-out duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in duration-200"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div
                    v-if="modal"
                    :class="modal ? 'fixed' : 'absolute h-0 w-0'"
                    class="inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                ></div>
            </transition>
            <div class="inset-0 z-10 overflow-y-auto" :class="modal ? 'fixed' : 'absolute h-0 w-0'">
                <div
                    :class="
                        modal
                            ? 'flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'
                            : 'absolute h-0 w-0'
                    "
                >
                    <transition
                        enter-active-class="ease-out duration-300"
                        enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                        leave-active-class="ease-in duration-200"
                        leave-class="opacity-100 translate-y-0 sm:scale-100"
                        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <VerifyEmailModal v-if="modal" />
                    </transition>
                </div>
            </div>
        </div>
        <!-- /modals -->
    </div>
</template>

<script lang="js">
import StatusNotification from '@/components/shared/StatusNotification.vue';
import VerifyEmailModal from '@/components/modals/VerifyEmailModal.vue';
import AppNavigation from '@/components/navigation/AppNavigation.vue';

export default {
  name: 'App',
  components: { VerifyEmailModal, StatusNotification, AppNavigation},
  computed: {
    notifications() {
      return this.$store.getters['notification/notifications'];
    },
    authenticatedUser() {
      return this.$store.getters['user/authenticatedUser'];
    },
    modal() {
      return this.$store.getters['modal/modal'];
    },
  }
  }
</script>
