import router from '@/router';

import Jsona from 'jsona';

import TicketService from '@/services/TicketService';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    ticketIsLoading: false,
    ticketsAreLoading: false,
    tickets: [],
    ticket: undefined,
};

export const mutations = {
    ADD_TICKET(state, payload) {
        state.tickets.push(payload);
    },
    DELETE_TICKET(state, payload) {
        const index = state.tickets.findIndex((value) => value.id === payload.id);
        state.tickets.splice(index, 1);
    },
    SET_TICKET_LOADING_STATUS(state, payload) {
        state.ticketIsLoading = payload;
    },
    SET_TICKETS_LOADING_STATUS(state, payload) {
        state.ticketsAreLoading = payload;
    },
    SET_TICKETS(state, payload) {
        state.tickets = payload;
    },
    SET_TICKET(state, payload) {
        state.ticket = payload;
    },
};

export const actions = {
    async fetchTickets({ commit }, params) {
        try {
            commit('SET_TICKETS_LOADING_STATUS', true);

            const response = await TicketService.fetchTickets(params);
            const tickets = dataFormatter.deserialize(response.data);

            commit('SET_TICKETS', tickets);
            commit('SET_TICKETS_LOADING_STATUS', false);

            return tickets;
        } catch (error) {
            commit('SET_TICKETS', []);
            commit('SET_TICKETS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async fetchTicket({ commit }, params) {
        try {
            commit('SET_TICKET_LOADING_STATUS', true);

            const response = await TicketService.fetchTicket(params);
            const ticket = dataFormatter.deserialize(response.data);

            commit('SET_TICKET', ticket);
            commit('SET_TICKET_LOADING_STATUS', false);

            return ticket;
        } catch (error) {
            commit('SET_TICKET', undefined);
            commit('SET_TICKETS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async saveTicket({ commit, dispatch }, payload) {
        let { method, ticket } = payload;

        try {
            commit('SET_TICKET_LOADING_STATUS', true);

            delete ticket.links;

            const response = await TicketService.saveTicket(
                method,
                dataFormatter.serialize({
                    stuff: { ...ticket, type: 'tickets' },
                })
            );

            ticket = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_TICKET', ticket);
            }

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your ticket has been ${method === 'POST' ? 'created' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_TICKET_LOADING_STATUS', false);

            return ticket;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.message,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_TICKET_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async deleteTicket({ commit, dispatch }, payload) {
        try {
            commit('SET_TICKET_LOADING_STATUS', true);

            const response = await TicketService.deleteTicket(payload);

            commit('SET_TICKET_LOADING_STATUS', false);
            commit('DELETE_TICKET', payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: 'That ticket has been removed.',
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            await router
                .replace({
                    name: 'tickets.summary',
                })
                .catch(() => {
                    //...
                });

            return await response;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: `${err.message}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_TICKET_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    setTicket({ commit }, payload) {
        commit('SET_TICKET', payload);
    },
};

export const getters = {
    tickets: (state) => {
        return state.tickets;
    },
    ticket: (state) => {
        return state.ticket;
    },
    ticketsAreLoading: (state) => {
        return state.ticketsAreLoading;
    },
    ticketIsLoading: (state) => {
        return state.ticketIsLoading;
    },
};
