import client from '@/helpers/axios';

export default {
    async saveLeague(method, league) {
        if (method === 'PATCH') {
            try {
                return await client.patch(`/v1/leagues/${league.data.id}`, league);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/leagues`, league);
            } catch (err) {
                return await Promise.reject(err);
            }
        }
    },
    async fetchLeagues() {
        try {
            return await client.get(`/v1/leagues`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async fetchLeague(params) {
        const { leagueId } = params;

        try {
            return await client.get(`/v1/leagues/${leagueId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async deleteLeague(leagueId) {
        try {
            return await client.delete(`/v1/leagues/${leagueId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
