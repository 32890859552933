export const namespaced = true;

export const state = {
    notifications: [],
};

let nextId = 1;

export const mutations = {
    PUSH(state, notification) {
        state.notifications.push({
            ...notification,
            id: nextId++,
        });
    },
    DELETE(state, notificationToRemove) {
        state.notifications = state.notifications.filter(function (notification) {
            return notification.id !== notificationToRemove.id;
        });
    },
};

export const actions = {
    addNotification({ commit }, value) {
        commit('PUSH', value);
    },
    removeNotification({ commit }, value) {
        commit('DELETE', value);
    },
};

export const getters = {
    notifications: (state) => {
        return state.notifications;
    },
};
