import Jsona from 'jsona';

import SupportService from '@/services/SupportService';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    supportIsLoading: false,
    ticketIsLoading: false,
    tickets: [],
    ticket: undefined,
};

export const mutations = {
    SET_SUPPORT_LOADING_STATUS(state, payload) {
        state.supportIsLoading = payload;
    },
    SET_TICKETS(state, payload) {
        state.tickets = payload;
    },
    SET_TICKET(state, payload) {
        state.ticket = payload;
    },
};

export const actions = {
    async requestSupport({ commit, dispatch }, payload) {
        let { method, organizationId, ticket } = payload;

        try {
            commit('SET_SUPPORT_LOADING_STATUS', true);

            ticket.phoneNumber = ticket.phoneNumber.replace(/[^+\d]+/g, '');
            ticket.organizationId = organizationId;

            delete ticket.links;

            console.log('TKT:', ticket);

            const response = await SupportService.requestSupport(
                method,
                dataFormatter.serialize({
                    stuff: { ...ticket, type: 'support-tickets' },
                })
            );

            ticket = dataFormatter.deserialize(response.data);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your request has been ${method === 'POST' ? 'received' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_SUPPORT_LOADING_STATUS', false);

            return ticket;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.message,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_SUPPORT_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async fetchSupportTickets({ commit, dispatch }, payload) {
        let { organization } = payload;

        dispatch('application/handleNetworkError', undefined, {
            root: true,
        });

        commit('SET_SUPPORT_LOADING_STATUS', true);

        try {
            const response = await SupportService.fetchSupportTickets(organization.id);
            const tickets = dataFormatter.deserialize(response.data);

            commit('SET_TICKETS', tickets);
            commit('SET_SUPPORT_LOADING_STATUS', false);

            return organization;
        } catch (err) {
            commit('SET_TICKETS', []);
            commit('SET_SUPPORT_LOADING_STATUS', false);

            dispatch('application/handleNetworkError', err, {
                root: true,
            });

            return false;
        }
    },
    setTicket({ commit }, payload) {
        commit('SET_TICKET', payload);
    },
};

export const getters = {
    supportIsLoading: (state) => {
        return state.supportIsLoading;
    },
    tickets: (state) => {
        return state.tickets;
    },
    ticket: (state) => {
        return state.ticket;
    },
};
