import client from '@/helpers/axios';

export default {
    async saveUserGroup(method, userGroup) {
        if (method === 'PATCH') {
            try {
                return await client.patch(`/v1/user-groups/${userGroup.data.id}`, userGroup);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/user-groups`, userGroup);
            } catch (err) {
                return await Promise.reject(err);
            }
        }
    },
    async fetchUserGroups() {
        try {
            return await client.get(`/v1/user-groups`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async fetchUserGroup(params) {
        const { userGroupId } = params;

        try {
            return await client.get(`/v1/user-groups/${userGroupId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async deleteUserGroup(userGroupId) {
        try {
            return await client.delete(`/v1/user-groups/${userGroupId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
