import client from '@/helpers/axios';

export default {
    async authenticate(payload) {
        const { organizationId } = payload;

        try {
            return await client.post(`/v1/integrations/wheniwork/authenticate`, {
                organizationId,
            });
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async fetchLocations() {
        try {
            return await client.get(`/v1/integrations/wheniwork/locations`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async fetchPositions() {
        try {
            return await client.get(`/v1/integrations/wheniwork/positions`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
