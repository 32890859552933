import client from '@/helpers/axios';

export default {
    async saveBlackout(method, blackout) {
        if (method === 'PATCH') {
            try {
                return await client.patch(`/v1/blackouts/${blackout.data.id}`, blackout);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/blackouts`, blackout);
            } catch (err) {
                return await Promise.reject(err);
            }
        }
    },
    async fetchBlackouts() {
        try {
            return await client.get(`/v1/blackouts`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async fetchBlackout(params) {
        const { blackoutId } = params;

        try {
            return await client.get(`/v1/blackouts/${blackoutId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async deleteBlackout(blackoutId) {
        try {
            return await client.delete(`/v1/blackouts/${blackoutId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
