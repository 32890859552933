import axios from 'axios';

const client = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Cache-Control': null,
        'X-Requested-With': null,
    },
});

export default client;
