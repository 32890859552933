import client from '@/helpers/axios';

export default {
    async fetchLocations(params) {
        const { organizationId } = params;

        try {
            return await client.get(`/v1/organizations/${organizationId}/locations`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async fetchLocation(params) {
        const { locationId } = params;

        try {
            return await client.get(`/v1/locations/${locationId}?include`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async saveLocation(method, location) {
        const { phoneNumber } = location.data.attributes;

        location.data.attributes.phoneNumber = phoneNumber.replace(/[^+\d]+/g, '');

        if (method === 'PATCH') {
            try {
                return await client.patch(`/v1/locations/${location.data.id}`, location);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/locations`, location);
            } catch (err) {
                return await Promise.reject(err);
            }
        }
    },
    async deleteLocation(locationId) {
        try {
            return await client.delete(`/v1/locations/${locationId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
