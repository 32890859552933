import Cookies from 'js-cookie';
import axios from 'axios';

export default {
    async fetchContactLists() {
        try {
            return await axios.get('https://api.cc.email/v3/contact_lists', {
                headers: {
                    Authorization: `Bearer ${Cookies.get('ccAccessToken')}`,
                },
            });
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
