import Jsona from 'jsona';

import OrganizationService from '@/services/OrganizationService';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    organization: localStorage.getItem('organization')
        ? JSON.parse(localStorage.getItem('organization'))
        : undefined,
    organizationsAreLoading: false,
    organizationIsLoading: false,
};

export const mutations = {
    SET_ORGANIZATION_LOADING_STATUS(state, payload) {
        state.organizationIsLoading = payload;
    },
    SET_ORGANIZATIONS_LOADING_STATUS(state, payload) {
        state.organizationsAreLoading = payload;
    },
    SET_ORGANIZATION(state, payload) {
        state.organization = payload;

        if (payload) {
            localStorage.setItem('organization', JSON.stringify(payload));
        } else {
            localStorage.removeItem('organization');
        }
    },
};

export const actions = {
    async fetchOrganization({ commit, dispatch }, organizationId) {
        dispatch('application/handleNetworkError', undefined, {
            root: true,
        });

        commit('SET_ORGANIZATION_LOADING_STATUS', true);

        try {
            const response = await OrganizationService.fetchOrganization(organizationId);
            const organization = dataFormatter.deserialize(response.data);

            commit('SET_ORGANIZATION', organization);
            commit('SET_ORGANIZATION_LOADING_STATUS', false);

            return organization;
        } catch (err) {
            commit('SET_ORGANIZATION', undefined);
            commit('SET_ORGANIZATION_LOADING_STATUS', false);

            dispatch('application/handleNetworkError', err, {
                root: true,
            });

            return false;
        }
    },
    async saveOrganization({ commit, dispatch }, payload) {
        let { method, organization } = payload;

        try {
            commit('SET_ORGANIZATION_LOADING_STATUS', true);

            delete organization.links;

            const response = await OrganizationService.saveOrganization(
                method,
                dataFormatter.serialize({
                    stuff: { ...organization, type: 'organizations' },
                })
            );

            organization = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_ORGANIZATION', organization);
            }

            commit('SET_ORGANIZATION', organization);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your organization has been ${method === 'POST' ? 'created' : 'updated'}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_ORGANIZATION_LOADING_STATUS', false);

            return organization;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.message,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_ORGANIZATION_LOADING_STATUS', false);

            return false;
        }
    },
    setOrganization({ commit }, payload) {
        commit('SET_ORGANIZATION', payload);
    },
};

export const getters = {
    organizations: (state) => {
        return state.organizations;
    },
    organization: (state) => {
        return state.organization;
    },
    organizationsAreLoading: (state) => {
        return state.organizationsAreLoading;
    },
    organizationIsLoading: (state) => {
        return state.organizationIsLoading;
    },
};
