import Jsona from 'jsona';

import BlackoutService from '@/services/BlackoutService';
import router from '@/router';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    blackoutIsLoading: false,
    blackoutsAreLoading: false,
    blackouts: [],
    blackout: undefined,
};

export const mutations = {
    ADD_BLACKOUT(state, payload) {
        state.blackouts.push(payload);
    },
    DELETE_BLACKOUT(state, payload) {
        const index = state.blackouts.findIndex((value) => value.id === payload.id);
        state.blackouts.splice(index, 1);
    },
    SET_BLACKOUT_LOADING_STATUS(state, payload) {
        state.blackoutIsLoading = payload;
    },
    SET_BLACKOUTS_LOADING_STATUS(state, payload) {
        state.blackoutsAreLoading = payload;
    },
    SET_BLACKOUTS(state, payload) {
        state.blackouts = payload;
    },
    SET_BLACKOUT(state, payload) {
        state.blackout = payload;
    },
};

export const actions = {
    async saveBlackout({ commit, dispatch }, payload) {
        let { method, organizationId, locationId, blackout } = payload;

        try {
            commit('SET_BLACKOUT_LOADING_STATUS', true);

            blackout.organizationId = organizationId;
            blackout.locationId = locationId;

            delete blackout.links;

            const response = await BlackoutService.saveBlackout(
                method,
                dataFormatter.serialize({
                    stuff: { ...blackout, type: 'blackouts' },
                })
            );

            blackout = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_BLACKOUT', blackout);
            }

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your blackout has been ${method === 'POST' ? 'created' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_BLACKOUT_LOADING_STATUS', false);

            return blackout;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.message,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_BLACKOUT_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async fetchBlackouts({ commit }) {
        commit('SET_BLACKOUTS_LOADING_STATUS', true);

        try {
            const response = await BlackoutService.fetchBlackouts();
            const blackouts = dataFormatter.deserialize(response.data);

            commit('SET_BLACKOUTS', blackouts);
            commit('SET_BLACKOUTS_LOADING_STATUS', false);

            return await blackouts;
        } catch (err) {
            commit('SET_BLACKOUTS', []);
            commit('SET_BLACKOUTS_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    async fetchBlackout({ commit }, params) {
        try {
            commit('SET_BLACKOUT_LOADING_STATUS', true);

            const response = await BlackoutService.fetchBlackout(params);
            const blackout = dataFormatter.deserialize(response.data);

            commit('SET_BLACKOUT', blackout);
            commit('SET_BLACKOUT_LOADING_STATUS', false);

            return await blackout;
        } catch (error) {
            commit('SET_BLACKOUT', undefined);
            commit('SET_BLACKOUTS_LOADING_STATUS', false);

            return await Promise.reject(error);
        }
    },
    async deleteBlackout({ commit, dispatch }, payload) {
        try {
            commit('SET_BLACKOUT_LOADING_STATUS', true);

            const response = await BlackoutService.deleteBlackout(payload);

            commit('SET_BLACKOUT_LOADING_STATUS', false);
            commit('DELETE_BLACKOUT', payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: 'That blackout has been removed.',
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            await router
                .replace({
                    name: 'blackouts.summary',
                })
                .catch(() => {
                    //...
                });

            return await response;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: `${err.message}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_BLACKOUT_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    setBlackout({ commit }, payload) {
        commit('SET_BLACKOUT', payload);
    },
};

export const getters = {
    blackouts: (state) => {
        return state.blackouts;
    },
    blackout: (state) => {
        return state.blackout;
    },
    blackoutsAreLoading: (state) => {
        return state.blackoutsAreLoading;
    },
    blackoutIsLoading: (state) => {
        return state.blackoutIsLoading;
    },
};
