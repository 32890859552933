import client from '@/helpers/axios';

export default {
    async saveClinic(method, clinic) {
        if (method === 'PATCH') {
            try {
                return await client.patch(`/v1/clinics/${clinic.data.id}`, clinic);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        if (method === 'POST') {
            try {
                return await client.post(`/v1/clinics`, clinic);
            } catch (err) {
                return await Promise.reject(err);
            }
        }
    },
    async fetchClinics() {
        try {
            return await client.get(`/v1/clinics`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async fetchClinic(params) {
        const { clinicId } = params;

        try {
            return await client.get(`/v1/clinics/${clinicId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
    async deleteClinic(clinicId) {
        try {
            return await client.delete(`/v1/clinics/${clinicId}`);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
