import router from '@/router';

import Jsona from 'jsona';

import LocationService from '@/services/LocationService';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    locationIsLoading: false,
    locationsAreLoading: false,
    locations: [],
    location: localStorage.getItem('location')
        ? JSON.parse(localStorage.getItem('location'))
        : undefined,
};

export const mutations = {
    ADD_LOCATION(state, payload) {
        state.locations.push(payload);
    },
    DELETE_LOCATION(state, payload) {
        const index = state.locations.findIndex((value) => value.id === payload.id);
        state.locations.splice(index, 1);
    },
    SET_LOCATION_LOADING_STATUS(state, payload) {
        state.locationIsLoading = payload;
    },
    SET_LOCATIONS_LOADING_STATUS(state, payload) {
        state.locationsAreLoading = payload;
    },
    SET_LOCATIONS(state, payload) {
        state.locations = payload;
    },
    SET_LOCATION(state, payload) {
        state.location = payload;

        if (payload) {
            localStorage.setItem('location', JSON.stringify(payload));
        } else {
            localStorage.removeItem('location');
        }
    },
};

export const actions = {
    async fetchLocations({ commit }, params) {
        try {
            commit('SET_LOCATIONS_LOADING_STATUS', true);

            const response = await LocationService.fetchLocations(params);
            const locations = dataFormatter.deserialize(response.data);

            commit('SET_LOCATIONS', locations);
            commit('SET_LOCATIONS_LOADING_STATUS', false);

            return locations;
        } catch (error) {
            commit('SET_LOCATIONS', []);
            commit('SET_LOCATIONS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async fetchLocation({ commit }, params) {
        try {
            commit('SET_LOCATION_LOADING_STATUS', true);

            const response = await LocationService.fetchLocation(params);
            const location = dataFormatter.deserialize(response.data);

            commit('SET_LOCATION', location);
            commit('SET_LOCATION_LOADING_STATUS', false);

            return location;
        } catch (error) {
            commit('SET_LOCATION', undefined);
            commit('SET_LOCATION_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async saveLocation({ commit, dispatch }, payload) {
        let { method, location, organizationId } = payload;

        try {
            commit('SET_LOCATION_LOADING_STATUS', true);

            location.organizationId = organizationId;

            delete location.links;

            const response = await LocationService.saveLocation(
                method,
                dataFormatter.serialize({
                    stuff: { ...location, type: 'locations' },
                })
            );

            location = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_LOCATION', location);
            }

            commit('SET_LOCATION', location);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your location has been ${method === 'POST' ? 'created' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_LOCATION_LOADING_STATUS', false);

            return location;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.message,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_LOCATION_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async deleteLocation({ commit, dispatch }, payload) {
        try {
            commit('SET_LOCATION_LOADING_STATUS', true);

            const response = await LocationService.deleteLocation(payload);

            commit('SET_LOCATION_LOADING_STATUS', false);
            commit('DELETE_LOCATION', payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: 'That location has been removed.',
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            await router
                .replace({
                    name: 'locations.summary',
                })
                .catch(() => {
                    //...
                });

            return await response;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: `${err.message}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_LOCATION_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    setLocation({ commit }, payload) {
        commit('SET_LOCATION', payload);
    },
};

export const getters = {
    locations: (state) => {
        return state.locations;
    },
    location: (state) => {
        return state.location;
    },
    locationsAreLoading: (state) => {
        return state.locationsAreLoading;
    },
    locationIsLoading: (state) => {
        return state.locationIsLoading;
    },
};
