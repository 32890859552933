import router from '@/router';

import Jsona from 'jsona';

import ArtifactService from '@/services/ArtifactService';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    artifactIsLoading: false,
    artifactsAreLoading: false,
    artifacts: [],
    artifact: undefined,
};

export const mutations = {
    ADD_ARTIFACT(state, payload) {
        state.artifacts.push(payload);
    },
    DELETE_ARTIFACT(state, payload) {
        const index = state.artifacts.findIndex((value) => value.id === payload.id);
        state.artifacts.splice(index, 1);
    },
    SET_ARTIFACT_LOADING_STATUS(state, payload) {
        state.artifactIsLoading = payload;
    },
    SET_ARTIFACTS_LOADING_STATUS(state, payload) {
        state.artifactsAreLoading = payload;
    },
    SET_ARTIFACTS(state, payload) {
        state.artifacts = payload;
    },
    SET_ARTIFACT(state, payload) {
        state.artifact = payload;
    },
};

export const actions = {
    async fetchArtifacts({ commit }, params) {
        try {
            commit('SET_ARTIFACTS_LOADING_STATUS', true);

            const response = await ArtifactService.fetchArtifacts(params);
            const artifacts = dataFormatter.deserialize(response.data);

            commit('SET_ARTIFACTS', artifacts);
            commit('SET_ARTIFACTS_LOADING_STATUS', false);

            return artifacts;
        } catch (error) {
            commit('SET_ARTIFACTS', []);
            commit('SET_ARTIFACTS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async fetchArtifact({ commit }, params) {
        try {
            commit('SET_ARTIFACT_LOADING_STATUS', true);

            const response = await ArtifactService.fetchArtifact(params);
            const artifact = dataFormatter.deserialize(response.data);

            commit('SET_ARTIFACT', artifact);
            commit('SET_ARTIFACT_LOADING_STATUS', false);

            return artifact;
        } catch (error) {
            commit('SET_ARTIFACT', undefined);
            commit('SET_ARTIFACTS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async saveArtifact({ commit, dispatch }, payload) {
        let { method, artifact } = payload;

        try {
            commit('SET_ARTIFACT_LOADING_STATUS', true);

            delete artifact.links;

            const response = await ArtifactService.saveArtifact(
                method,
                dataFormatter.serialize({
                    stuff: { ...artifact, type: 'artifacts' },
                })
            );

            artifact = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_ARTIFACT', artifact);
            }

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your artifact has been ${method === 'POST' ? 'created' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_ARTIFACT_LOADING_STATUS', false);

            return artifact;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.message,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_ARTIFACT_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async deleteArtifact({ commit, dispatch }, payload) {
        try {
            commit('SET_ARTIFACT_LOADING_STATUS', true);

            const response = await ArtifactService.deleteArtifact(payload);

            commit('SET_ARTIFACT_LOADING_STATUS', false);
            commit('DELETE_ARTIFACT', payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: 'That artifact has been removed.',
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            await router
                .replace({
                    name: 'artifacts.summary',
                })
                .catch(() => {
                    //...
                });

            return await response;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: `${err.message}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_ARTIFACT_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    setArtifact({ commit }, payload) {
        commit('SET_ARTIFACT', payload);
    },
};

export const getters = {
    artifacts: (state) => {
        return state.artifacts;
    },
    artifact: (state) => {
        return state.artifact;
    },
    artifactsAreLoading: (state) => {
        return state.artifactsAreLoading;
    },
    artifactIsLoading: (state) => {
        return state.artifactIsLoading;
    },
};
