const routes = [
    {
        path: '/login/',
        name: 'auth.login',
        component: () =>
            import(/* webpackChunkName: 'auth.login' */ '@/views/authentication/LoginPage.vue'),
    },
    {
        path: '/forgot',
        name: 'auth.forgot',
        component: () =>
            import(/* webpackChunkName: 'auth.forgot' */ '@/views/authentication/ForgotPage.vue'),
    },
    {
        path: '/challenge',
        name: 'auth.challenge',
        component: () =>
            import(
                /* webpackChunkName: 'auth.challenge' */ '@/views/authentication/ChallengePage.vue'
            ),
    },
    {
        path: '/reset',
        name: 'auth.reset',
        component: () =>
            import(/* webpackChunkName: 'auth.reset' */ '@/views/authentication/ResetPage.vue'),
    },
    {
        path: '/dashboard',
        name: 'app.dashboard',
        meta: { requiresAuth: true },
        component: () =>
            import(/* webpackChunkName: 'app.dashboard' */ '@/views/dashboard/DashboardPage.vue'),
    },
    {
        path: '/support',
        name: 'app.support',
        component: () =>
            import(/* webpackChunkName: 'auth.forgot' */ '@/views/support/SupportPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/settings',
        name: '',
        component: () =>
            import(
                /* webpackChunkName: 'app.settings.summary' */ '@/views/settings/SettingsSummaryPage.vue'
            ),
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'app.settings',
                component: () =>
                    import(
                        /* webpackChunkName: 'app.settings' */ '@/views/settings/SettingsPage.vue'
                    ),
                meta: { requiresAuth: true },
            },
            {
                path: 'organizations/:organizationId',
                name: 'organization.settings',
                component: () =>
                    import(
                        /* webpackChunkName: 'organization.settings' */ '@/views/settings/organizations/OrganizationSettingsPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    return props;
                },
            },
            {
                path: 'organizations/:organizationId/locations',
                name: 'locations.settings.summary',
                component: () =>
                    import(
                        /* webpackChunkName: 'locations.settings.summary' */ '@/views/settings/locations/LocationsSettingsSummaryPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    return props;
                },
            },
            {
                path: 'organizations/:organizationId/users',
                name: 'users.settings.summary',
                component: () =>
                    import(
                        /* webpackChunkName: 'users.settings.summary' */ '@/views/settings/users/UsersSettingsSummaryPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    return props;
                },
            },
            {
                path: 'organizations/:organizationId/users/:userId',
                name: 'user.settings',
                component: () =>
                    import(
                        /* webpackChunkName: 'user.settings' */ '@/views/settings/users/UserSettingsPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    props.userId = route.params.userId;
                    return props;
                },
            },
            {
                path: 'activities',
                name: 'activities.settings.summary',
                component: () =>
                    import(
                        /* webpackChunkName: 'activities.settings.summary' */ '@/views/settings/activities/ActivitiesSettingsSummaryPage.vue'
                    ),
                meta: { requiresAuth: true },
            },
            {
                path: 'activities/:activityId',
                name: 'activity.settings',
                component: () =>
                    import(
                        /* webpackChunkName: 'activity.settings' */ '@/views/settings/activities/ActivitySettingsPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.activityId = route.params.activityId;
                    return props;
                },
            },
            {
                path: 'artifacts',
                name: 'artifacts.settings.summary',
                component: () =>
                    import(
                        /* webpackChunkName: 'artifacts.settings.summary' */ '@/views/settings/artifacts/ArtifactsSettingsSummaryPage.vue'
                    ),
                meta: { requiresAuth: true },
            },
            {
                path: 'artifacts/:artifactId',
                name: 'artifact.settings',
                component: () =>
                    import(
                        /* webpackChunkName: 'artifact.settings' */ '@/views/settings/artifacts/ArtifactSettingsPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.artifactId = route.params.artifactId;
                    return props;
                },
            },
            {
                path: 'assets',
                name: 'assets.settings.summary',
                component: () =>
                    import(
                        /* webpackChunkName: 'assets.settings.summary' */ '@/views/settings/assets/AssetsSettingsSummaryPage.vue'
                    ),
                meta: { requiresAuth: true },
            },
            {
                path: 'assets/:assetId',
                name: 'asset.settings',
                component: () =>
                    import(
                        /* webpackChunkName: 'asset.settings' */ '@/views/settings/assets/AssetSettingsPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.assetId = route.params.assetId;
                    return props;
                },
            },
            {
                path: 'user-groups',
                name: 'userGroups.settings.summary',
                component: () =>
                    import(
                        /* webpackChunkName: 'userGroups.settings.summary' */ '@/views/settings/userGroups/UserGroupsSettingsSummaryPage.vue'
                    ),
                meta: { requiresAuth: true },
            },
            {
                path: 'user-groups/:userGroupId',
                name: 'userGroup.settings',
                component: () =>
                    import(
                        /* webpackChunkName: 'userGroup.settings' */ '@/views/settings/userGroups/UserGroupSettingsPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.userGroupId = route.params.userGroupId;
                    return props;
                },
            },
        ],
    },
    {
        path: '/settings/organizations/:organizationId/locations/:locationId',
        component: () =>
            import(
                /* webpackChunkName: 'location.settings.summary' */ '@/views/settings/locations/LocationSettingsSummaryPage.vue'
            ),
        meta: { requiresAuth: true },
        props(route) {
            const props = { ...route.params };
            props.organizationId = route.params.organizationId;
            return props;
        },
        children: [
            {
                path: '',
                name: 'location.settings',
                component: () =>
                    import(
                        /* webpackChunkName: 'location.settings' */ '@/views/settings/locations/LocationSettingsPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    return props;
                },
            },
            {
                path: 'reservables',
                name: 'reservables.settings.summary',
                component: () =>
                    import(
                        /* webpackChunkName: 'reservables.settings.summary' */ '@/views/settings/reservables/ReservablesSettingsSummaryPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    props.locationId = route.params.locationId;
                    return props;
                },
            },
            {
                path: 'integrations',
                name: 'integrations.settings.summary',
                component: () =>
                    import(
                        /* webpackChunkName: 'integrations.settings.summary' */ '@/views/settings/integrations/IntegrationsSettingsSummaryPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    props.locationId = route.params.locationId;
                    return props;
                },
            },
        ],
    },
    {
        path: '/settings/organizations/:organizationId/locations/:locationId/reservables/:reservableId',
        name: 'reservable.settings.summary',
        component: () =>
            import(
                /* webpackChunkName: 'reservable.settings.summary' */ '@/views/settings/reservables/ReservableSettingsSummaryPage.vue'
            ),
        meta: { requiresAuth: true },
        props(route) {
            const props = { ...route.params };
            props.organizationId = route.params.organizationId;
            props.locationId = route.params.locationId;
            props.reservableId = route.params.reservableId;
            return props;
        },
        children: [
            {
                path: '/settings/organizations/:organizationId/locations/:locationId/reservables/:reservableId',
                name: 'reservable.settings',
                component: () =>
                    import(
                        /* webpackChunkName: 'reservable.settings' */ '@/views/settings/reservables/ReservableSettingsPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    props.locationId = route.params.locationId;
                    props.reservableId = route.params.reservableId;
                    return props;
                },
            },
            {
                path: '/settings/organizations/:organizationId/locations/:locationId/reservables/:reservableId/accessories',
                name: 'accessories.settings.summary',
                component: () =>
                    import(
                        /* webpackChunkName: 'accessories.settings.summary' */ '@/views/settings/accessories/AccessoriesSettingsSummaryPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    props.locationId = route.params.locationId;
                    props.reservableId = route.params.reservableId;
                    return props;
                },
            },
        ],
    },
    {
        path: '/organizations/:organizationId/locations',
        name: '',
        component: () =>
            import(
                /* webpackChunkName: 'locations.summary' */ '@/views/locations/LocationsSummaryPage.vue'
            ),
        meta: { requiresAuth: true },
        props(route) {
            const props = { ...route.params };
            props.organizationId = route.params.organizationId;
            return props;
        },
        children: [
            {
                path: '',
                name: 'locations.home',
                component: () =>
                    import(
                        /* webpackChunkName: 'locations.summary' */ '@/views/locations/LocationsHomePage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    return props;
                },
            },
        ],
    },
    {
        path: '/organizations/:organizationId/locations/:locationId',
        component: () =>
            import(
                /* webpackChunkName: 'location.details.summary' */ '@/views/locations/LocationDetailsSummaryPage.vue'
            ),
        meta: { requiresAuth: true },
        props(route) {
            const props = { ...route.params };
            props.organizationId = route.params.organizationId;
            return props;
        },
        children: [
            {
                path: '',
                name: 'location.details',
                component: () =>
                    import(
                        /* webpackChunkName: 'location.details' */ '@/views/locations/LocationDetailsPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    return props;
                },
            },
            {
                path: '/organizations/:organizationId/locations/:locationId/orders',
                name: 'orders.summary',
                component: () =>
                    import(
                        /* webpackChunkName: 'orders.summary' */ '@/views/orders/OrdersSummaryPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    props.locationId = route.params.locationId;
                    return props;
                },
            },
            {
                path: '/organizations/:organizationId/locations/:locationId/orders/:orderId',
                name: 'order.details',
                component: () =>
                    import(
                        /* webpackChunkName: 'order.details' */ '@/views/orders/OrderDetailsPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.orderId = route.params.orderId;
                    return props;
                },
            },
            {
                path: '/organizations/:organizationId/locations/:locationId/reservations',
                name: 'reservations.summary',
                component: () =>
                    import(
                        /* webpackChunkName: 'reservations.summary' */ '@/views/reservations/ReservationsSummaryPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    props.locationId = route.params.locationId;
                    return props;
                },
            },
            {
                path: '/organizations/:organizationId/locations/:locationId/reservations/:reservationId',
                name: 'reservation.details',
                component: () =>
                    import(
                        /* webpackChunkName: 'reservation.details' */ '@/views/reservations/ReservationDetailsPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.reservationId = route.params.reservationId;
                    return props;
                },
            },
            {
                path: '/organizations/:organizationId/locations/:locationId/events',
                name: 'events.summary',
                component: () =>
                    import(
                        /* webpackChunkName: 'events.summary' */ '@/views/events/EventsSummaryPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    props.locationId = route.params.locationId;
                    return props;
                },
            },
            {
                path: '/organizations/:organizationId/locations/:locationId/leagues',
                name: 'leagues.summary',
                component: () =>
                    import(
                        /* webpackChunkName: 'leagues.summary' */ '@/views/leagues/LeaguesSummaryPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    props.locationId = route.params.locationId;
                    return props;
                },
            },
            {
                path: '/organizations/:organizationId/locations/:locationId/leagues/:leagueId',
                name: 'league.details',
                component: () =>
                    import(
                        /* webpackChunkName: 'league.details' */ '@/views/leagues/LeagueDetailsPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.leagueId = route.params.leagueId;
                    return props;
                },
            },
            {
                path: '/organizations/:organizationId/locations/:locationId/clinics',
                name: 'clinics.summary',
                component: () =>
                    import(
                        /* webpackChunkName: 'clinics.summary' */ '@/views/clinics/ClinicsSummaryPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    props.locationId = route.params.locationId;
                    return props;
                },
            },
            {
                path: '/organizations/:organizationId/locations/:locationId/clinics/:clinicId',
                name: 'clinic.details',
                component: () =>
                    import(
                        /* webpackChunkName: 'clinic.details' */ '@/views/clinics/ClinicDetailsPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.clinicId = route.params.clinicId;
                    return props;
                },
            },
            {
                path: '/organizations/:organizationId/locations/:locationId/lessons',
                name: 'lessons.summary',
                component: () =>
                    import(
                        /* webpackChunkName: 'lessons.summary' */ '@/views/lessons/LessonsSummaryPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    props.locationId = route.params.locationId;
                    return props;
                },
            },
            {
                path: '/organizations/:organizationId/locations/:locationId/lessons/:lessonId',
                name: 'lesson.details',
                component: () =>
                    import(
                        /* webpackChunkName: 'lesson.details' */ '@/views/lessons/LessonDetailsPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.lessonId = route.params.lessonId;
                    return props;
                },
            },
        ],
    },
    {
        path: '/organizations/:organizationId/locations/:locationId/events/:eventId',
        component: () =>
            import(/* webpackChunkName: 'event.home' */ '@/views/events/EventHomePage.vue'),
        meta: { requiresAuth: true },
        props(route) {
            const props = { ...route.params };
            props.organizationId = route.params.organizationId;
            props.locationId = route.params.locationId;
            props.eventId = route.params.eventId;
            return props;
        },
        children: [
            {
                path: '',
                name: 'event.details',
                component: () =>
                    import(
                        /* webpackChunkName: 'event.details' */ '@/views/events/EventDetailsPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    props.locationId = route.params.locationId;
                    props.eventId = route.params.eventId;
                    return props;
                },
            },
            {
                path: 'tickets',
                name: 'event.tickets.home',
                component: () =>
                    import(
                        /* webpackChunkName: 'event.tickets.home' */ '@/views/tickets/TicketsSummaryPage.vue'
                    ),
                meta: { requiresAuth: true },
                props(route) {
                    const props = { ...route.params };
                    props.organizationId = route.params.organizationId;
                    props.locationId = route.params.locationId;
                    props.eventId = route.params.eventId;
                    return props;
                },
            },
        ],
    },
    {
        path: '/account',
        name: '',
        component: () =>
            import(
                /* webpackChunkName: 'user.account.summary' */ '@/views/settings/users/AccountSettingsSummaryPage.vue'
            ),
        meta: { requiresAuth: true },
        children: [
            {
                path: '/profile',
                name: 'user.profile',
                component: () =>
                    import(
                        /* webpackChunkName: 'user.profile' */ '@/views/settings/users/ProfilePage.vue'
                    ),
                meta: { requiresAuth: true },
            },
        ],
    },
    {
        path: '/settings/organizations/:organizationId/locations/:locationId/integrations/cc-redirect',
        name: 'cc.redirect',
        component: () =>
            import(
                /* webpackChunkName: 'cc.redirect' */ '@/views/settings/integrations/vendors/constant-contact/RedirectPage.vue'
            ),
        meta: { requiresAuth: true },
        props(route) {
            const props = { ...route.params };
            props.organizationId = route.params.organizationId;
            return props;
        },
    },
];
export default routes;
