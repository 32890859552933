import WhenIWorkService from '@/services/WhenIWorkService';

export const namespaced = true;

export const state = {
    isAuthenticating: false,
    locationsAreLoading: false,
    positionsAreLoading: false,
    accessToken: '',
    locations: [],
    positions: [],
};

export const mutations = {
    SET_LOCATIONS(state, payload) {
        state.locations = payload;
    },
    SET_LOCATIONS_LOADING_STATUS(state, payload) {
        state.locationsAreLoading = payload;
    },
    SET_POSITIONS_LOADING_STATUS(state, payload) {
        state.positionsAreLoading = payload;
    },
    SET_AUTHENTICATING_STATUS(state, payload) {
        state.isAuthenticating = payload;
    },
    SET_POSITIONS(state, payload) {
        state.positions = payload;
    },
    SET_ACCESS_TOKEN(state, paylod) {
        state.accessToken = paylod;
    },
};

export const actions = {
    async authenticate({ commit }, payload) {
        try {
            commit('SET_AUTHENTICATING_STATUS', true);

            const response = await WhenIWorkService.authenticate(payload);

            commit('SET_ACCESS_TOKEN', response.data);
            commit('SET_AUTHENTICATING_STATUS', false);
        } catch (err) {
            commit('SET_AUTHENTICATING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    async fetchLocations({ commit }) {
        try {
            commit('SET_LOCATIONS_LOADING_STATUS', true);

            const response = await WhenIWorkService.fetchLocations();

            commit('SET_LOCATIONS', response.data.locations);
            commit('SET_LOCATIONS_LOADING_STATUS', false);

            return response;
        } catch (error) {
            console.log('whenIWork.fetchLocations error: ', error);

            commit('SET_LOCATIONS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async fetchPositions({ commit }) {
        try {
            commit('SET_POSITIONS_LOADING_STATUS', true);

            const response = await WhenIWorkService.fetchPositions();

            commit('SET_POSITIONS', response.data.positions);
            commit('SET_POSITIONS_LOADING_STATUS', false);

            return response;
        } catch (error) {
            console.log('whenIWork.fetchPositions error: ', error);
            commit('SET_POSITIONS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
};

export const getters = {
    locationsAreLoading: (state) => {
        return state.locationsAreLoading;
    },
    positionsAreLoading: (state) => {
        return state.positionsAreLoading;
    },
    isAuthenticating: (state) => {
        return state.isAuthenticating;
    },
    locations: (state) => {
        return state.locations;
    },
    positions: (state) => {
        return state.positions;
    },
    accessToken: (state) => {
        return state.accessToken;
    },
};
