import Jsona from 'jsona';

import OrderService from '@/services/OrderService';
import router from '@/router';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    orderIsLoading: false,
    ordersAreLoading: false,
    orders: [],
    order: undefined,
    meta: undefined,
    links: '',
};

export const mutations = {
    ADD_ORDER(state, payload) {
        state.orders.push(payload);
    },
    DELETE_ORDER(state, payload) {
        const index = state.orders.findIndex((value) => value.id === payload.id);
        state.orders.splice(index, 1);
    },
    SET_ORDER_LOADING_STATUS(state, payload) {
        state.orderIsLoading = payload;
    },
    SET_ORDERS_LOADING_STATUS(state, payload) {
        state.ordersAreLoading = payload;
    },
    SET_ORDERS(state, payload) {
        state.orders = payload;
    },
    SET_META(state, payload) {
        state.meta = payload;
    },
    SET_LINKS(state, payload) {
        state.links = payload;
    },
    SET_ORDER(state, payload) {
        state.order = payload;
    },
};

export const actions = {
    async fetchOrders({ commit }, params) {
        try {
            commit('SET_ORDERS_LOADING_STATUS', true);

            const response = await OrderService.fetchOrders(params);
            const orders = dataFormatter.deserialize(response.data);

            commit('SET_ORDERS', orders);
            commit('SET_META', response.data.meta);
            commit('SET_LINKS', response.data.links);
            commit('SET_ORDERS_LOADING_STATUS', false);

            return orders;
        } catch (error) {
            commit('SET_ORDERS', []);
            commit('SET_ORDERS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async fetchOrder({ commit }, params) {
        try {
            commit('SET_ORDER_LOADING_STATUS', true);

            const response = await OrderService.fetchOrder(params);
            const order = dataFormatter.deserialize(response.data);

            commit('SET_ORDER', order);
            commit('SET_ORDER_LOADING_STATUS', false);

            return order;
        } catch (error) {
            commit('SET_ORDER', undefined);
            commit('SET_ORDERS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async saveOrder({ commit, dispatch }, payload) {
        let { method, order, organizationId, locationId } = payload;

        try {
            commit('SET_ORDER_LOADING_STATUS', true);

            order.organizationId = organizationId;
            order.locationId = locationId;
            order.relationshipNames = ['reservations'];
            order.phoneNumber = order.phoneNumber.replace(/[^+\d]+/g, '');

            delete order.links;

            const response = await OrderService.saveOrder(
                method,
                dataFormatter.serialize({
                    stuff: { ...order, type: 'orders' },
                })
            );

            order = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_ORDER', order);
            }

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your order has been ${method === 'POST' ? 'created' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_ORDER_LOADING_STATUS', false);

            return order;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.response.data.errors[0].detail,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_ORDER_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async deleteOrder({ commit, dispatch }, payload) {
        try {
            commit('SET_ORDER_LOADING_STATUS', true);

            const response = await OrderService.deleteOrder(payload);

            commit('SET_ORDER_LOADING_STATUS', false);
            commit('DELETE_ORDER', payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: 'That order has been removed.',
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            await router
                .replace({
                    name: 'orders.summary',
                })
                .catch(() => {
                    //...
                });

            return await response;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: `${err.message}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_ORDER_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    setOrder({ commit }, payload) {
        commit('SET_ORDER', payload);
    },
};

export const getters = {
    orders: (state) => {
        return state.orders;
    },
    order: (state) => {
        return state.order;
    },
    meta: (state) => {
        return state.meta;
    },
    links: (state) => {
        return state.links;
    },
    ordersAreLoading: (state) => {
        return state.ordersAreLoading;
    },
    orderIsLoading: (state) => {
        return state.orderIsLoading;
    },
};
