import Jsona from 'jsona';

import router from '@/router';

import ReservableService from '@/services/ReservableService';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    reservableIsLoading: false,
    reservablesAreLoading: false,
    reservables: [],
    reservable: undefined,
};

export const mutations = {
    ADD_RESERVABLE(state, payload) {
        state.reservables.push(payload);
    },
    DELETE_RESERVABLE(state, payload) {
        const index = state.reservables.findIndex((value) => value.id === payload.id);
        state.reservables.splice(index, 1);
    },
    SET_RESERVABLE_LOADING_STATUS(state, payload) {
        state.reservableIsLoading = payload;
    },
    SET_RESERVABLES_LOADING_STATUS(state, payload) {
        state.reservablesAreLoading = payload;
    },
    SET_RESERVABLES(state, payload) {
        state.reservables = payload;
    },
    SET_RESERVABLE(state, payload) {
        state.reservable = payload;
    },
};

export const actions = {
    async fetchReservables({ commit }, params) {
        try {
            commit('SET_RESERVABLES_LOADING_STATUS', true);

            const response = await ReservableService.fetchReservables(params);
            const reservables = dataFormatter.deserialize(response.data);

            commit('SET_RESERVABLES', reservables);
            commit('SET_RESERVABLES_LOADING_STATUS', false);

            return reservables;
        } catch (error) {
            commit('SET_RESERVABLES', []);
            commit('SET_RESERVABLES_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async fetchReservable({ commit }, params) {
        try {
            commit('SET_RESERVABLE_LOADING_STATUS', true);

            const response = await ReservableService.fetchReservable(params);
            const reservable = dataFormatter.deserialize(response.data);

            commit('SET_RESERVABLE', reservable);
            commit('SET_RESERVABLE_LOADING_STATUS', false);

            return reservable;
        } catch (error) {
            commit('SET_RESERVABLE', undefined);
            commit('SET_RESERVABLES_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async saveReservable({ commit, dispatch }, payload) {
        let { method, reservable, organizationId, locationId } = payload;

        try {
            commit('SET_RESERVABLE_LOADING_STATUS', true);

            reservable.organizationId = organizationId;
            reservable.locationId = locationId;
            reservable.relationshipNames = ['activities'];

            delete reservable.links;

            const response = await ReservableService.saveReservable(
                method,
                dataFormatter.serialize({
                    stuff: { ...reservable, type: 'reservables' },
                })
            );

            reservable = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_RESERVABLE', reservable);
            }

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your reservable has been ${method === 'POST' ? 'created' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_RESERVABLE_LOADING_STATUS', false);

            return reservable;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.message,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_RESERVABLE_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async deleteReservable({ commit, dispatch }, payload) {
        try {
            commit('SET_RESERVABLE_LOADING_STATUS', true);

            const response = await ReservableService.deleteReservable(payload);

            commit('SET_RESERVABLE_LOADING_STATUS', false);
            commit('DELETE_RESERVABLE', payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: 'That reservable has been removed.',
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            await router
                .replace({
                    name: 'reservables.settings.summary',
                })
                .catch(() => {
                    //...
                });

            return await response;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: `${err.message}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_RESERVABLE_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    setReservable({ commit }, payload) {
        commit('SET_RESERVABLE', payload);
    },
};

export const getters = {
    reservables: (state) => {
        return state.reservables;
    },
    reservable: (state) => {
        return state.reservable;
    },
    reservablesAreLoading: (state) => {
        return state.reservablesAreLoading;
    },
    reservableIsLoading: (state) => {
        return state.reservableIsLoading;
    },
};
