import Vue from 'vue';
import Vuex from 'vuex';

import * as application from '@/store/modules/application';
import * as notification from '@/store/modules/notification';
import * as user from '@/store/modules/user';
import * as organization from '@/store/modules/organization';
import * as location from '@/store/modules/location';
import * as activity from '@/store/modules/activity';
import * as event from '@/store/modules/event';
import * as artifact from '@/store/modules/artifact';
import * as asset from '@/store/modules/asset';
import * as userGroup from '@/store/modules/userGroup';
import * as league from '@/store/modules/league';
import * as clinic from '@/store/modules/clinic';
import * as ticket from '@/store/modules/ticket';
import * as lesson from '@/store/modules/lesson';
import * as reservation from '@/store/modules/reservation';
import * as order from '@/store/modules/order';
import * as reservable from '@/store/modules/reservable';
import * as accessory from '@/store/modules/accessory';
import * as blackout from '@/store/modules/blackout';
import * as integration from '@/store/modules/integration';
import * as modal from '@/store/modules/modal';
import * as file from '@/store/modules/file';
import * as support from '@/store/modules/support';
import * as whenIWork from '@/store/modules/whenIWork';
import * as constantContact from '@/store/modules/constantContact';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        application,
        notification,
        user,
        organization,
        location,
        activity,
        artifact,
        ticket,
        clinic,
        asset,
        lesson,
        event,
        league,
        userGroup,
        reservation,
        order,
        reservable,
        accessory,
        blackout,
        integration,
        modal,
        file,
        support,
        whenIWork,
        constantContact,
    },
});
