import Jsona from 'jsona';

import ActivityService from '@/services/ActivityService';
import router from '@/router';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    activityIsLoading: false,
    activitiesAreLoading: false,
    activities: [],
    activity: undefined,
};

export const mutations = {
    ADD_ACTIVITY(state, payload) {
        state.activities.push(payload);
    },
    DELETE_ACTIVITY(state, payload) {
        const index = state.activities.findIndex((value) => value.id === payload.id);
        state.activities.splice(index, 1);
    },
    SET_ACTIVITY_LOADING_STATUS(state, payload) {
        state.activityIsLoading = payload;
    },
    SET_ACTIVITIES_LOADING_STATUS(state, payload) {
        state.activitiesAreLoading = payload;
    },
    SET_ACTIVITIES(state, payload) {
        state.activities = payload;
    },
    SET_ACTIVITY(state, payload) {
        state.activity = payload;
    },
};

export const actions = {
    async saveActivity({ commit, dispatch }, payload) {
        let { method, activity } = payload;

        try {
            commit('SET_ACTIVITY_LOADING_STATUS', true);

            delete activity.links;

            const response = await ActivityService.saveActivity(
                method,
                dataFormatter.serialize({
                    stuff: { ...activity, type: 'activities' },
                })
            );

            activity = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_ACTIVITY', activity);
            }

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `Your activity has been ${method === 'POST' ? 'created' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_ACTIVITY_LOADING_STATUS', false);

            return activity;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.message,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_ACTIVITY_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async fetchActivities({ commit }) {
        commit('SET_ACTIVITIES_LOADING_STATUS', true);

        try {
            const response = await ActivityService.fetchActivities();
            const activities = dataFormatter.deserialize(response.data);

            commit('SET_ACTIVITIES', activities);
            commit('SET_ACTIVITIES_LOADING_STATUS', false);

            return await activities;
        } catch (err) {
            commit('SET_ACTIVITIES', []);
            commit('SET_ACTIVITIES_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    async fetchActivity({ commit }, params) {
        try {
            commit('SET_ACTIVITY_LOADING_STATUS', true);

            const response = await ActivityService.fetchActivity(params);
            const activity = dataFormatter.deserialize(response.data);

            commit('SET_ACTIVITY', activity);
            commit('SET_ACTIVITY_LOADING_STATUS', false);

            return await activity;
        } catch (error) {
            commit('SET_ACTIVITY', undefined);
            commit('SET_ACTIVITY_LOADING_STATUS', false);

            return await Promise.reject(error);
        }
    },
    async deleteActivity({ commit, dispatch }, payload) {
        try {
            commit('SET_ACTIVITY_LOADING_STATUS', true);

            const response = await ActivityService.deleteActivity(payload);

            commit('SET_ACTIVITY_LOADING_STATUS', false);
            commit('DELETE_ACTIVITY', payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: 'That activity has been removed.',
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            await router
                .replace({
                    name: 'activities.summary',
                })
                .catch(() => {
                    //...
                });

            return await response;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: `${err.message}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_ACTIVITY_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    setActivity({ commit }, payload) {
        commit('SET_ACTIVITY', payload);
    },
};

export const getters = {
    activities: (state) => {
        return state.activities;
    },
    activity: (state) => {
        return state.activity;
    },
    activitiesAreLoading: (state) => {
        return state.activitiesAreLoading;
    },
    activityIsLoading: (state) => {
        return state.activityIsLoading;
    },
};
