<template>
    <div class="hidden h-full lg:flex lg:flex-shrink-0">
        <div class="flex w-20 flex-col">
            <div class="flex min-h-0 flex-1 flex-col overflow-y-auto bg-gray-700">
                <div class="flex-1">
                    <div class="flex items-center justify-center bg-gray-600 py-4">
                        <svg
                            fill="none"
                            viewBox="0 0 35 32"
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-8 w-auto"
                        >
                            <path
                                fill="#fff"
                                d="M15.258 26.865a4.043 4.043 0 01-1.133 2.917A4.006 4.006 0 0111.253 31a3.992 3.992 0 01-2.872-1.218 4.028 4.028 0 01-1.133-2.917c.009-.698.2-1.382.557-1.981.356-.6.863-1.094 1.47-1.433-.024.109.09-.055 0 0l1.86-1.652a8.495 8.495 0 002.304-5.793c0-2.926-1.711-5.901-4.17-7.457.094.055-.036-.094 0 0A3.952 3.952 0 017.8 7.116a3.975 3.975 0 01-.557-1.98 4.042 4.042 0 011.133-2.918A4.006 4.006 0 0111.247 1a3.99 3.99 0 012.872 1.218 4.025 4.025 0 011.133 2.917 8.521 8.521 0 002.347 5.832l.817.8c.326.285.668.551 1.024.798.621.33 1.142.826 1.504 1.431a3.902 3.902 0 01-1.504 5.442c.033-.067-.063.036 0 0a8.968 8.968 0 00-3.024 3.183 9.016 9.016 0 00-1.158 4.244zM19.741 5.123c0 .796.235 1.575.676 2.237a4.01 4.01 0 001.798 1.482 3.99 3.99 0 004.366-.873 4.042 4.042 0 00.869-4.386 4.02 4.02 0 00-1.476-1.806 3.994 3.994 0 00-5.058.501 4.038 4.038 0 00-1.175 2.845zM23.748 22.84c-.792 0-1.567.236-2.226.678a4.021 4.021 0 00-1.476 1.806 4.042 4.042 0 00.869 4.387 3.99 3.99 0 004.366.873A4.01 4.01 0 0027.08 29.1a4.039 4.039 0 00-.5-5.082 4 4 0 00-2.832-1.18zM34 15.994c0-.796-.235-1.574-.675-2.236a4.01 4.01 0 00-1.798-1.483 3.99 3.99 0 00-4.367.873 4.042 4.042 0 00-.869 4.387 4.02 4.02 0 001.476 1.806 3.993 3.993 0 002.226.678 4.003 4.003 0 002.832-1.18A4.04 4.04 0 0034 15.993z"
                            />
                            <path
                                fill="#fff"
                                d="M5.007 11.969c-.793 0-1.567.236-2.226.678a4.021 4.021 0 00-1.476 1.807 4.042 4.042 0 00.869 4.386 4.001 4.001 0 004.366.873 4.011 4.011 0 001.798-1.483 4.038 4.038 0 00-.5-5.08 4.004 4.004 0 00-2.831-1.181z"
                            />
                        </svg>
                    </div>
                    <nav aria-label="Sidebar" class="flex flex-col items-center space-y-3 py-6">
                        <!-- dashboard -->
                        <router-link
                            :to="{ name: 'app.dashboard' }"
                            :active-class="'bg-gray-600'"
                            class="group flex items-center rounded-lg p-4 transition duration-75 ease-linear hover:bg-gray-600"
                            title="Dashboard"
                        >
                            <svg
                                class="h-6 w-6 text-gray-300 transition duration-75 ease-linear group-hover:text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                />
                            </svg>
                            <span class="sr-only">Dashboard</span>
                        </router-link>
                        <!-- /dashboard -->

                        <!-- locations -->
                        <router-link
                            :to="{
                                name: 'locations.home',
                                params: {
                                    organizationId: organization.id,
                                },
                            }"
                            :active-class="'bg-gray-600'"
                            class="group flex items-center rounded-lg p-4 transition duration-75 ease-linear hover:bg-gray-600"
                            title="Manage locations"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-6 w-6 text-gray-300 transition duration-75 ease-linear group-hover:text-white"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                                />
                            </svg>
                            <span class="sr-only">Locations</span>
                        </router-link>
                        <!-- /locations -->

                        <!-- settings -->
                        <router-link
                            :to="{ name: 'app.settings' }"
                            :active-class="'bg-gray-600'"
                            title="Application settings"
                            class="group flex items-center rounded-lg p-4 transition duration-75 ease-linear hover:bg-gray-600"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-6 w-6 text-gray-300 transition duration-75 ease-linear group-hover:text-white"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                />
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                            </svg>
                            <span class="sr-only">Settings</span>
                        </router-link>
                        <!-- /settings -->
                    </nav>
                </div>

                <div class="mx-auto flex flex-shrink-0 flex-col items-center space-y-3 pb-5">
                    <!-- support -->
                    <router-link
                        :to="{ name: 'app.support' }"
                        :active-class="'bg-gray-600'"
                        title="Application settings"
                        class="group flex items-center rounded-lg p-4 transition duration-75 ease-linear hover:bg-gray-600"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 text-gray-300 transition duration-75 ease-linear group-hover:text-white"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                            />
                        </svg>
                        <span class="sr-only">Support</span>
                    </router-link>
                    <!-- /support -->

                    <!-- account -->
                    <router-link
                        :to="{ name: 'user.profile' }"
                        :active-class="'bg-gray-600'"
                        class="group flex items-center rounded-lg p-4 transition duration-75 ease-linear hover:bg-gray-600"
                        title="Account preferences"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 text-gray-300 transition duration-75 ease-linear group-hover:text-white"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                        <span class="sr-only">Account</span>
                    </router-link>
                    <!-- /account -->
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
export default {
  name: 'AppNavigation',
  computed: {
    organization() {
      return this.$store.getters['organization/organization'];
    },
  }
}
</script>
