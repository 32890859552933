<template>
    <div
        class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
    >
        <div class="p-4">
            <div class="flex items-start">
                <div class="flex-shrink-0">
                    <svg
                        v-if="notification.type === 'SUCCESS'"
                        class="h-6 w-6 text-green-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>

                    <svg
                        v-if="notification.type === 'ERROR'"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-red-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                    <p class="text-sm font-medium leading-5 text-gray-800">
                        {{ notification.title }}
                    </p>
                    <p class="mt-1 text-sm leading-5 text-gray-700">
                        {{ notification.message }}
                    </p>
                </div>
                <div class="ml-4 flex flex-shrink-0">
                    <button
                        class="inline-flex rounded-md bg-white text-gray-400 transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        @click="closeNotification(notification)"
                    >
                        <span class="sr-only">Close</span>
                        <svg
                            class="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatusNotification',
    props: {
        notification: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    data() {
        return {
            timeout: null,
        };
    },
    mounted() {
        this.timeout = setTimeout(() => {
            this.closeNotification(this.notification);
        }, 3000);
    },
    beforeDestroy() {
        clearTimeout(this.timeout);
    },
    methods: {
        async closeNotification(notification) {
            try {
                await this.$store.dispatch('notification/removeNotification', notification);
            } catch (error) {
                return Promise.reject();
            }
        },
    },
};
</script>
