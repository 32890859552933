import Jsona from 'jsona';

import ReservationService from '@/services/ReservationService';
import router from '@/router';

const dataFormatter = new Jsona();

export const namespaced = true;

export const state = {
    reservationIsLoading: false,
    reservationsAreLoading: false,
    reservations: [],
    reservation: undefined,
};

export const mutations = {
    ADD_RESERVATION(state, payload) {
        state.reservations.push(payload);
    },
    DELETE_RESERVATION(state, payload) {
        const index = state.reservations.findIndex((value) => value.id === payload.id);
        state.reservations.splice(index, 1);
    },
    SET_RESERVATION_LOADING_STATUS(state, payload) {
        state.reservationIsLoading = payload;
    },
    SET_RESERVATIONS_LOADING_STATUS(state, payload) {
        state.reservationsAreLoading = payload;
    },
    SET_RESERVATIONS(state, payload) {
        state.reservations = payload;
    },
    SET_RESERVATION(state, payload) {
        state.reservation = payload;
    },
};

export const actions = {
    async fetchReservations({ commit }, params) {
        try {
            commit('SET_RESERVATIONS_LOADING_STATUS', true);

            const response = await ReservationService.fetchReservations(params);
            const reservations = dataFormatter.deserialize(response.data);

            commit('SET_RESERVATIONS', reservations);
            commit('SET_RESERVATIONS_LOADING_STATUS', false);

            return reservations;
        } catch (error) {
            commit('SET_RESERVATIONS', []);
            commit('SET_RESERVATIONS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async fetchReservation({ commit }, params) {
        try {
            commit('SET_RESERVATION_LOADING_STATUS', true);

            const response = await ReservationService.fetchReservation(params);
            const reservation = dataFormatter.deserialize(response.data);

            commit('SET_RESERVATION', reservation);
            commit('SET_RESERVATION_LOADING_STATUS', false);

            return reservation;
        } catch (error) {
            commit('SET_RESERVATION', undefined);
            commit('SET_RESERVATIONS_LOADING_STATUS', false);

            return Promise.reject(error);
        }
    },
    async saveReservation({ commit, dispatch }, payload) {
        let { method, reservation, organizationId, locationId } = payload;

        try {
            commit('SET_RESERVATION_LOADING_STATUS', true);

            reservation.organizationId = organizationId;
            reservation.locationId = locationId;
            reservation.relationshipNames = ['order'];

            delete reservation.links;

            const response = await ReservationService.saveReservation(
                method,
                dataFormatter.serialize({
                    stuff: { ...reservation, type: 'reservations' },
                })
            );

            reservation = dataFormatter.deserialize(response.data);

            if (method === 'POST') {
                commit('ADD_RESERVATION', reservation);
            }

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: `That reservation has been ${method === 'POST' ? 'created' : 'updated'}.`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_RESERVATION_LOADING_STATUS', false);

            return reservation;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: err.response.data.errors[0].detail,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_RESERVATION_LOADING_STATUS', false);

            return Promise.reject(err);
        }
    },
    async deleteReservation({ commit, dispatch }, payload) {
        try {
            commit('SET_RESERVATION_LOADING_STATUS', true);

            const response = await ReservationService.deleteReservation(payload);

            commit('SET_RESERVATION_LOADING_STATUS', false);
            commit('DELETE_RESERVATION', payload);

            const notification = {
                type: 'SUCCESS',
                title: 'Got it!',
                message: 'That reservation has been removed.',
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            await router
                .replace({
                    name: 'reservations.summary',
                })
                .catch(() => {
                    //...
                });

            return await response;
        } catch (err) {
            const notification = {
                type: 'ERROR',
                title: 'Uh-oh!',
                message: `${err.message}`,
            };

            dispatch('notification/addNotification', notification, {
                root: true,
            });

            commit('SET_RESERVATION_LOADING_STATUS', false);

            return await Promise.reject(err);
        }
    },
    setReservation({ commit }, payload) {
        commit('SET_RESERVATION', payload);
    },
};

export const getters = {
    reservations: (state) => {
        return state.reservations;
    },
    reservation: (state) => {
        return state.reservation;
    },
    reservationsAreLoading: (state) => {
        return state.reservationsAreLoading;
    },
    reservationIsLoading: (state) => {
        return state.reservationIsLoading;
    },
};
