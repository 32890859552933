import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import routes from '@/router/routes';

import VueJwtDecode from 'vue-jwt-decode';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((routeRecord) => routeRecord.meta.requiresAuth)) {
        if (store.getters['user/accessToken']) {
            const { exp } = VueJwtDecode.decode(store.getters['user/accessToken']);

            if (exp > (new Date().getTime() + 1) / 1000) {
                next();
            } else {
                if (store.getters['user/refreshToken']) {
                    store
                        .dispatch('user/refreshAccessToken', {
                            refreshToken: store.getters['user/refreshToken'],
                            user: store.getters['user/authenticatedUser'],
                        })
                        .then(() => {
                            next();
                        })
                        .catch(() => {
                            store.dispatch('user/signOut').then(() => {
                                //...
                            });
                        });
                } else {
                    store.dispatch('user/signOut').then(() => {
                        //...
                    });
                }
            }
        } else {
            store.dispatch('user/signOut').then(() => {
                //...
            });
        }
    } else {
        next();
    }
});

export default router;
